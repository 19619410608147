import React from 'react'
import { Navbar,Nav,NavDropdown,Form,FormControl,Button } from 'react-bootstrap'

const year = new Date().getFullYear()
class Footer extends React.Component{
    constructor(props){
        super(props);
        this.state = {  
            copyright : {
                "en":{
                    "tagline":"Copyright @ "+year+"    All Rights Reserved.",
                },
                "zh":{
                    "tagline":"海克斯國際有限公司 @ "+year+"    版權所有",
                },
                "cn":{
                    "tagline":"海克斯国际有限公司 @ "+year+"    版权所有",
                },
            }
        };
    
        this.openlink = this.openlink.bind(this)
      }
    
      openlink(link){
        window.open(link)
      }
    render(){
        return(
            <div className="footer">
                <div className="footer-img-wrapper">
                    <a href="https://www.facebook.com/International-Young-Artist-Open-Competition-110636931788689" target="_blank">
                    <img src="../../assets/fb48.png" alt="Facebook" />
                    </a>
                    <a href="https://www.instagram.com/iyaocompetition/" target="_blank">
                    <img src="../../assets/ig48.png" alt="Instagram" />
                    </a>
                    <img src="/assets/yt_logo.PNG" className={"footer-bottomlogo"} height={"65px"} alt={"YT_LOGO"} style={{marginTop:"5px"}} />
                    <a href="https://ytmagasin.com/" target="_blank" style={{marginLeft:"60px"}}>
                    <img src="../../assets/yt-magasin-logo.jpeg" width={"150px"} alt="YT Magasin" style={{marginTop:"5px"}} />
                    </a>
                    {/* <a href="https://www.youtube.com" target="_blank">
                    <img src="/assets/yt48.png" alt="YouTube" />
                    </a> */}
                </div>

                <div className="footer-text-center">
                    <p style={{fontFamily:"Courgette"}}>{this.state.copyright[this.props.lang]["tagline"]}</p>
                </div>
            </div>
        )  
    }
}

export default Footer;