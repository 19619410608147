const iyfaaoPrice = {

    "PW1":40,
    "PW2":40,
    "PW3":40,
    "PW4":40,
    "PW5":50,
    "PW6":50,

    "PP1":40,
    "PP2":40,
    "PP3":40,
    "PP4":40,
    "PP5":50,
    "PP6":50,

    "PI1":40,
    "PI2":40,
    "PI3":40,
    "PI4":40,
    "PI5":50,
    "PI6":50,

    "PS1":40,
    "PS2":40,
    "PS3":40,
    "PS4":40,
    "PS5":50,
    "PS6":50,

    "PO1":40,
    "PO2":40,
    "PO3":40,
    "PO4":40,
    "PO5":50,
    "PO6":50,

    "PA1":40,
    "PA2":40,
    "PA3":40,
    "PA4":40,
    "PA5":50,
    "PA6":50,

    "TE1":40,
    "TE2":40,
    "TE3":40,
    "TE4":40,
    "TE5":50,
    "TE6":50,

    // "TT1":55,
    // "TT2":55,
    // "TT3":55,
    // "TT4":65,
    // "TT5":65,
    // "TT6":75,

    // "TD1":55,
    // "TD2":55,
    // "TD3":55,
    // "TD4":65,
    // "TD5":65,
    // "TD6":75,

    // "C1":47,
    // "C2":47,
    // "C3":47,

    "SC1":40,
    "SC2":40,
    "SC3":40,
    "SC4":40,
    "SC5":50,
    "SC6":50,

    "SH1":40,
    "SH2":40,
    "SH3":40,
    "SH4":40,
    "SH5":50,
    "SH6":50,

    "TEST":0.01,
}

export default iyfaaoPrice;