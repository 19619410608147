import '../App.css';
import React, {Component, Container} from 'react';
import {Row,Col,Image,Navbar, Nav, NavItem, NavDropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import YoutubeBackground from 'react-youtube-background';
// import NavBarTop from "./navbar";
// import Footer from "./footer";

// email: hicusinvestment@outlook.com pw:Investhicus2022
//fixer.io exchange rate
//http://data.fixer.io/api/latest?access_key=c06cd1b831f00ec6c85cbac41eb810b1
// params: latest, historical, convert, time-series, fluctuation

//coinlayer.com cryptocurrency
//http://api.coinlayer.com/api/live?access_key=bca90b9e4854ff3c17f9d4fd088d661b
// params: list, live historical, convert, timeframe, change

//https://news.bitcoin.com/
class IndxePage extends Component {
  constructor(props){
    super(props);
    this.state = {
      
    };

    this.setval = this.setval.bind(this)
  }

  setval(obj){
    this.setState(obj)
  }
  componentDidMount(){ 
  }      
      
  render(){
    return (
      <div style={{padding:"1% 1%"}}>
      <div className="home-content-div">
        {/* <div>
            <img  src="./assets/about.png" alt="" style={{height:"100%","width":"100%",paddingLeft:"1.7%",paddingRight:"1.7%"}}/>
        </div> */}

        <div style={{fontSize:"2.3rem",margin:"30px 0px",fontFamily:"Courgette"}}>PLEASE SELECT YOUR COMPETITION</div>

        <div className="indexpage-cardwrapper">
          
          <div className={["indexpage-card"].join(" ")} onClick={(e)=>{this.props.setval({comp:"wpjmc"})}}>
            <a href="/wpjmc">
                <img src="assets/wpjm-poster2025.PNG" width="100%" />
                <div style={{fontSize:"1.5rem",margin:"25px 10px",fontFamily:"Courgette"}}>
                  {"Worldwide Pop and Jazz Music Competition"}
                </div>
            </a>
            </div>

          {/* <div className={["indexpage-card"].join(" ")} onClick={(e)=>{this.props.setval({comp:"iyfao"})}} >
          <a href="/iyfao">
                <img src="assets/iyfao-poster-2024.png" width="100%" />
                <div style={{fontSize:"1.5rem",margin:"25px 10px",fontFamily:"Courgette"}}>
                {"International Young Fine Art Artist Open Competition (Art)"}
                </div>
          
                </a>
          </div>

          <div className={["indexpage-card"].join(" ")} onClick={(e)=>{this.props.setval({comp:"iesad"})}} >
          <a href="/iesad">
                <img src="assets/iesad_poster.png" width="100%" />
                <div style={{fontSize:"1.5rem",margin:"25px 10px",fontFamily:"Courgette"}}>
                {"International English Speech Art And Drama Competition"}
                </div>
          
                </a>
          </div>
  */}


        </div>

      </div>

       
        
        
          
      </div>
    );
  }
  }
  

export default IndxePage;
