import '../../App.css';
import React, {Component, Container} from 'react';
import {Image,Navbar, Nav, NavItem, NavDropdown} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import YoutubeBackground from 'react-youtube-background';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import NavBarTop from "./navbar";
import Footer from "./footer";
const applyurl = "https://docs.google.com/forms/d/e/1FAIpQLScoSIrXM_CqsXcaw6mSZmB8f6E1MoXcis3QFeRaE_SO6cP_5A/viewform?usp=sf_link"

class IYAOCNTerms extends Component {
  constructor(props){
    super(props);
    this.state = {  
       
    };

    this.setval = this.setval.bind(this)
  }
  componentDidMount(){
    this.props.setval({comp:"iyao-cn",lang:"cn"}) 
  }
  setval(obj){
    this.setState(obj)
  }

  render(){
    return (
      <div>
       <img src="/assets/term.png" width="100%" style={{borderRadius:"0px"}} />
      <div style={{display:"flex",flexDirection:"column",paddingLeft:"15%",paddingRight:"15%",paddingTop:"4%",paddingBottom:"2%",textAlign:"left",fontFamily:"Courgette"}}>
      <h4 style={{fontSize:"1.8em"}}><u><i>{"比赛规则"}</i></u></h4>
      <ul style={{textAlign:"justify"}}>
      <li><p style={{fontSize:"1.4em"}}>{"比赛向国际申请者开放。 对申请人的原籍没有限制。"} </p></li>
         <li><p style={{fontSize:"1.4em"}}>{"所有评审团成员不得评判自己学生提交的作品或作为参赛者参加比赛。"}</p></li>
       <li><p style={{fontSize:"1.4em"}}>{"参赛者不得联系任何评审团成员。 违规可能会导致取消资格。"}</p></li>
       <li><p style={{fontSize:"1.4em"}}>{"评审团成员的结果和评论是最终决定，无可争议。"}</p></li>
       <li><p style={{fontSize:"1.4em"}}>{"主办方有权拒绝和/或取消违反竞赛规则和规定的参赛者的参赛资格。 "}</p></li>
       <li><p style={{fontSize:"1.4em"}}>{"参赛者可以凭记忆或根据分数进行表演。"}</p></li>
       <li><p style={{fontSize:"1.4em"}}>{"主办方有权修改竞赛规则、奖品内容及评审团组成。 任何修改可能不会被公布。"}</p></li>
       <li><p style={{fontSize:"1.4em"}}>{"大赛官方规则以官方网站公布。"}</p></li>
       <li><p style={{fontSize:"1.4em"}}>{"主办方拥有最终解释权。"}</p></li>
    </ul>
      
     
    
    <br></br>

    <h4 style={{fontSize:"1.8em"}}><u><i>{"条款"}</i></u></h4>
    <ul style={{textAlign:"justify"}}>
    <li><p style={{fontSize:"1.4em"}}>{"参赛者有权申请多个比赛组别。"} </p></li>
       <li><p style={{fontSize:"1.4em"}}>{"所有申请费均不予退还。"}</p></li>
     <li><p style={{fontSize:"1.4em"}}>{"参赛者的所有资料将予以保密，并仅用于与本次比赛相关的联络、通知及推广事宜。"}</p ></li>
     <li><p style={{fontSize:"1.4em"}}>{"申请或提交信息任何部分的任何变更均，主办方有权收取 15 美元或等值的管理费。"} </p></li>
    </ul>
       <br></br>

{/* 
        <h2>2022 International Young Artist Open Competition</h2>
        <h4>Deadline: 15th November 2022</h4>
        <h4>Results Date: 19th December 2022</h4>
        <br/>      

      <br/><br/>
    <h6>After successful payment, we will send a confirmation email to you.</h6>
    <p style={{fontSize:"0.4em"}}>*All application fees are non-refundable.</p> */}
      </div>
      </div>
    );
  }
  }
  

export default IYAOCNTerms;
