import '../../App.css';
import './category.scss';
import React, { Component } from 'react';
import { Row, Col, Button, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import YoutubeBackground from 'react-youtube-background';

import NavBarTop from "./navbar";
import Footer from "./footer";
import groupCat from '../../cat';
import pricelist from '../../pricelist';

class Category extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //tooltip: { visible: false, left: 0, top: 0 },
      group: groupCat["jazz"],
      instrument: "jazz",
    };

    this.changeGroup = this.changeGroup.bind(this);
    this.setval = this.setval.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
  }

  componentWillUnmount(){
  }
  componentDidMount() {
    document.title = "Worldwide Pop and Jazz Music Competition";
    const link1 = document.getElementById('favicon');
    const link2 = document.getElementById('touchicon');
    link1.href = "/assets/wpjm-logo.PNG";
    link2.href = "/assets/wpjm-logo.PNG";
    this.props.setval({ comp: "wpjmc" });
  }

  setval(obj) {
    this.setState(obj);
  }

  handleMouseEnter(e) {
    this.setState({
      tooltip: {
        visible: true,
        left: e.clientX,
        top: e.clientY,
      },
    });
  }

  handleMouseLeave() {
    this.setState({ tooltip: { visible: false, left: 0, top: 0 } });
  }

  handleClick(category, number) {
    this.props.setval({ applyGroup: number });
    this.handleMouseLeave(); // Hide tooltip after clicking
  }

  changeGroup(val) {
    const _group = JSON.parse(JSON.stringify(groupCat[val]));
    this.setState({ group: _group, instrument: val });
  }

  render() {
    return (
      <div className="category-div">
        <video loop autoPlay muted id="myVideo">
          <source src="../assets/category.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <div id="cat-content">
          <button className={[(this.state.instrument === "jazz") ? "cat-button-selected" : "cat-button"].join(" ")}
            style={{ fontWeight: "bold", marginBottom: "7px" }} onClick={() => this.changeGroup("jazz")}>JAZZ</button>
          <button className={[(this.state.instrument === "countrymusic") ? "cat-button-selected" : "cat-button"].join(" ")}
            style={{ fontWeight: "bold", marginBottom: "7px" }} onClick={() => this.changeGroup("countrymusic")}>COUNTRY MUSIC</button>
          <button className={[(this.state.instrument === "r&b") ? "cat-button-selected" : "cat-button"].join(" ")}
            style={{ fontWeight: "bold", marginBottom: "7px" }} onClick={() => this.changeGroup("r&b")}>{"R&B"}</button>
          <button className={[(this.state.instrument === "pop") ? "cat-button-selected" : "cat-button"].join(" ")}
            style={{ fontWeight: "bold", marginBottom: "7px" }} onClick={() => this.changeGroup("pop")}>{"POP"}</button>
          <button className={[(this.state.instrument === "rock") ? "cat-button-selected" : "cat-button"].join(" ")}
            style={{ fontWeight: "bold", marginBottom: "7px" }} onClick={() => this.changeGroup("rock")}>ROCK</button>
        </div>

        <div className="jazz-display">
          {Object.keys(this.state.group).map((category) => (
            <div key={category} className="category-table">
              <h2>{category}</h2>
              <table>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Number</th>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Price (USD)</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.values(this.state.group[category]).map((item) => (
                    <tr key={item.id}
                      // onMouseEnter={this.handleMouseEnter}
                      // onMouseLeave={this.handleMouseLeave}
                      // onClick={() => this.handleClick(category, item.number)}
                    >
                      <td>{item.id}</td>
                      <td>{item.number}</td>
                      <td>{item.name}</td>
                      <td>{item.des}</td>
                      <td>{item.price}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}
        </div>

       
      </div>
    );
  }
}

export default Category;