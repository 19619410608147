import '../../App.css';
import React, {Component, Container} from 'react';
import {Row,Col,Image,Navbar, Nav, NavItem, NavDropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import YoutubeBackground from 'react-youtube-background';
import Card from './card';
import NavBarTop from "./navbar";
import Footer from "./footer";


class Contact extends Component {
  constructor(props){
    super(props);
    this.state = {
       
    };

    this.setval = this.setval.bind(this)
  }

  setval(obj){
    this.setState(obj)
  }
  componentDidMount(){
    document.title="Worldwide Pop and Jazz Music Competition"
    var link1 = document.getElementById('favicon')
    var link2 = document.getElementById('touchicon')
    link1.href="/assets/wpjm-logo.PNG"
    link2.href="/assets/wpjm-logo.PNG"
    this.props.setval({comp:"wpjmc"}) 
  }      
      
  render(){
    return (
      <div>
        <img src="../assets/contact-banner.jpg" alt="" width="100%" style={{borderRadius:"0px"}} />

      <div className="home-content-div" style={{textAlign:"justify",textJustify:"inter-word", padding:"4% 6%",fontFamily:"Courgette"}}>
        
        <h2>Contact Us</h2>

        <div style={{display:"flex"}}>
            <div style={{margin:"30px 4%",fontSize:"1.4rem"}}>
                {"For any further enquires, please contact us and we shall reply to you soon."}
            </div>
        </div>

        <div style={{display:"flex"}}>
            <div style={{marginLeft:"2%"}}>
                <img src="../assets/contact-email.jpg" alt="Email " width={"55px"} height={"55px"}/>
            </div>
            <div style={{marginLeft:"4%",marginTop:"14px"}}>
              <a href="mailto:wpjmcompetition@gmail.com?subject=Enquiry%20from%20website&amp;body=Dear WPJM,">
                    wpjmcompetition@gmail.com
                    </a>
                
            </div>
           
        </div>

        {/* <div style={{display:"flex"}}>
            <div style={{marginLeft:"2%"}}>
                <img src="../assets/contact-whatsapp.jpg" alt="WhatsApp " width={"55px"} height={"55px"}/>
            </div>
            <div style={{marginLeft:"4%",marginTop:"14px"}}>
                <h5>(852) 8491 8253 </h5>
            </div>
           
        </div> */}

           
        
      </div>
      </div>
    );
  }
  }
  

export default Contact;
