import '../../App.css';
import React, {Component, Container} from 'react';
import {Image,Navbar, Nav, NavItem, NavDropdown} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import YoutubeBackground from 'react-youtube-background';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
// import NavBarTop from "./navbar";
// import Footer from "./footer";
const applyurl = "https://docs.google.com/forms/d/e/1FAIpQLScoSIrXM_CqsXcaw6mSZmB8f6E1MoXcis3QFeRaE_SO6cP_5A/viewform?usp=sf_link"

class IYAOCNJury extends Component {
  constructor(props){
    super(props);
    this.state = {  
       
    };

    this.setval = this.setval.bind(this)
  }
  componentDidMount(){
    this.props.setval({comp:"iyao-cn",lang:"cn"})
  }
  setval(obj){
    this.setState(obj)
  }

  render(){
    return (
      <div>
       {/* <img src="/assets/term.png" width="100%" style={{borderRadius:"0px"}} /> */}
      <div style={{display:"flex",flexDirection:"column",paddingLeft:"15%",paddingRight:"15%",paddingTop:"3%",paddingBottom:"2%",textAlign:"left"}}>
      <h4 style={{fontSize:"1.8em",textAlign:"center",marginBottom:"100px"}}>{"现任和过去的评审团"}</h4>

      <div style={{display:'block', marginBottom:"100px"}}>
        <div className="jury-profile">
            <img src="/assets/andy.jpg" alt="" width="100%" />
            <h4 style={{marginTop:"5px"}}><b>{"安德烈亚斯·斯蒂尔教授"}</b></h4>
            <h5><em>{"钢琴家、教育家"}</em></h5>
            </div>
        <div style={{position:"absolute"}}>
            <img className="jury-flag" alt="" src="/assets/germany-flag.png" />
        </div>
        <div className="jury-text" style={{marginLeft:"4%"}}>
           <p>斯蒂尔教授在1952年出生于德国汉诺威，通过他的音乐家庭，他获得了多种
                 来自幼儿时期的灵感——钢琴音乐、室内乐，尤其是声乐。 曾在德国、韩国、台湾留学过，
                 他加入合唱团、学校管弦乐团（小提琴），并从事室内乐或声乐伴奏（钢琴）。
             </p>
                 <p>斯蒂尔教授在1983年春毕业于汉堡大学汉学专业，获文学硕士学位。
                     1983年秋，他毕业于汉堡HfMT（音乐戏剧大学）钢琴专业，并获得教师文凭。
                     1983年秋，他开始在汉堡 HfMT 担任钢琴教授。
             </p>
             <p>在 HfMT 工作的 38 年间，他还教授了许多来自中国、韩国和台湾的学生，试图让他们更接近“人的心态”。
                 西方音乐和作曲家。 在过去的八年里，他还在汉堡的一所钢琴学院教授日本学生。
             </p>
             <p>自1978年以来，斯蒂尔教授在一直在教授私人学生，其中一些学生正在为音乐大学的入学考试做准备，
                 其他人参加音乐比赛。 他曾担任德国官方青年音乐比赛 “Jugend Musiziert” 钢琴独奏评委
                 和钢琴室内乐三十多年。</p>
             <p>对于斯蒂尔教授在来说，演奏音乐一直是一种沟通方式，也是理解和克服文化距离的绝佳机会。 1978年，他与台湾妻子结婚。
                 他们有三个孩子和三个孙子。</p>
        </div>
    </div>

    <div style={{display:'block', marginBottom:"100px"}}>
        <div className="jury-text" style={{marginLeft:"0px",marginRight:"4%"}}>
        <p>
             萨金特博士的音乐生涯始于 6 岁加入合唱团
             威尔士班戈大教堂，并开始了钢琴课。 赢得风琴后
             获得剑桥大学三一音乐厅的奖学金，继续他的音乐剧
             师从弗雷德里克·普劳斯尼茨 (Frederik Prausnitz)，并于 2017 年获得音乐艺术博士学位。
             由美国皮博迪音乐学院指挥。 </p>
             <p>萨金特博士曾广泛游历欧洲和亚洲，从事教学、作曲和表演。 他是
             被称为高效的实践者。 他对表演的不懈倡导
             艺术给他带来了许多有益和丰富的经历，他希望
             与世界各地的年轻音乐家分享。 </p>
        </div>

        <div className="jury-profile" style={{position:"relative"}}>
        <div style={{position:"absolute"}}>
            <img className="jury-flag" alt="" src="/assets/uk-flag.png"/></div>
            <img src="/assets/dominic.jpg" alt=""  width="100%"/>
            <h4 style={{marginTop:"5px"}}><b>{"多米尼克·萨金特博士"}</b></h4>
            <h5><em>{"指挥家和音乐教育家"}</em></h5>
            </div>
        
        

    </div>

    <div style={{display:'block', marginBottom:"100px"}}>
        <div className="jury-profile">
            <img src="/assets/julia.jpg" alt="" width="100%"/>
            <h4 style={{marginTop:"5px"}}><b>{"朱莉娅·布什科娃教授"}</b></h4>
            <h5><em>{"“维奥伦蒂”乐团总监"}</em></h5>
            </div>
        <div style={{position:"absolute"}}>
            <img className="jury-flag" alt="" src="/assets/usa-flag.jpg" /></div>
        <div className="jury-text">
        <p>
            布什科娃教授被约瑟夫·金戈尔德 (Joseph Gingold) 誉为“最高级别的演奏家”，她曾作为独奏家与众多管弦乐团合作，并在美国各地举办独奏会。
             在她的祖国俄罗斯，以及德国、意大利、加拿大、英国、波兰、南非、墨西哥、智利和委内瑞拉。 “Schwarzwalder Bote”（德国）将她的演奏描述为“最高优雅的俄罗斯小提琴传统”。
             《大急流城报》和《安娜堡新闻报》强调“她非凡的表现力和异常宏大优美的音调……她令人眼花缭乱的华彩乐段和非凡的技术执行力。”</p>
             <p>布什科娃教授是 WBLV 和 WIAA 公共广播电台、WGVU-TV（密歇根州）、WFMT（芝加哥）、KOTM-FM 和 KGRS-FM（爱荷华州）、WRUV-FM（佛蒙特州）、WRR Classical 101.1 的特色表演者 （达拉斯）、哥伦比亚广播公司公共广播电台（加拿大）以及全国公共广播电台的《今日表演》。
             她最近的独奏和协奏曲演出足迹遍及莫斯科、圣彼得堡（俄罗斯）、明斯克（白俄罗斯）、纽约、北卡罗来纳州、密西西比州、俄克拉荷马州、密歇根州、加拿大、南非、意大利和委内瑞拉。</p>
             <p>朱莉娅·布什科娃出生于俄罗斯莫斯科的一个著名小提琴家家庭，五岁开始学习小提琴，十五岁在波兰首次演奏协奏曲。
             她以最高荣誉（优异成绩）毕业于莫斯科柴可夫斯基音乐学院，师从鲍罗丁弦乐四重奏的伊戈尔·贝兹罗德尼、佐里亚·希赫穆尔扎耶娃、康斯坦丁·阿杰莫夫和德米特里·舍巴林等教授。
             在此期间，她在匈牙利、波兰、保加利亚和前苏联演出，包括在莫斯科人民大会堂和国会宫举行的音乐会。</p>
            </div>
    </div>

    <div style={{display:'block', marginBottom:"100px"}}>

        <div className="jury-text" style={{marginLeft:"0px",marginRight:"4%"}}>
        <p>
             《纽约观察家报》称：“事实证明，俄罗斯钢琴家兼作曲家阿森蒂·哈里托诺夫是本演出季顶尖艺术家行列中的最新成员。”他将他在卡内基音乐厅的首演列为本演出季六强音乐会之一。
             Fanfare 杂志写道：“哈里托诺夫华丽的音色、精湛的技术、广泛的戏剧性范围和优雅的措辞使他跻身当今键盘手的顶尖行列。 超越这一点并击败当今大多数音乐体操运动员，
             这里有真正的诗意，有真理的光环，在我看来，有一种从钢琴家内心深处发出的罕见的真诚。”
             </p><p>
             来自圣彼得堡的哈里托诺夫教授是弗朗茨·李斯特国际钢琴比赛（美国）的一等奖获得者。 在赢得拉赫玛尼诺夫比赛（俄罗斯联邦）后，他开始了他的音乐会生涯，在欧洲各地进行演出，包括在爱乐音乐厅、
             以及圣彼得堡马林斯基剧院、斯德哥尔摩皇家音乐学院和赫尔辛基西贝柳斯学院，随后进行了亚洲巡演，在上海、北京和首尔等地的主要音乐厅演出。 在这片大陆上，
             他曾在渥太华的音乐与超越音乐节、华盛顿国家美术馆、盐湖城广场圣殿会幕、施坦威音乐厅、弗里克收藏馆和卡内基音乐厅与乐团合作演出。 纽约市的大厅。
        </p>
        <p>
        《观察家报》在谈到后一场音乐会时写道：“……他的风格不同于任何同时代的人，可以追溯到更早的时代。 动态范围具有惊人的广度，歌词段落尤其迷人……这是一场值得记住的表演。”
         </p></div>

       <div className="jury-profile" style={{position:"relative"}}>
            <div style={{position:"absolute"}}>
                <img className="jury-flag" alt="" src="/assets/russia-flag.png" /></div>
            <img src="/assets/arsentiy.jpg" alt=""  width="100%"/>
            <h4 style={{marginTop:"5px"}}><b>{"阿森蒂·哈里托诺夫教授"}</b></h4>
            <h5><em>{"国际钢琴大师、作曲家"}</em></h5>
            </div>
      

    </div>

    <div style={{display:'block', marginBottom:"100px"}}>
        <div className="jury-profile">
            <img src="/assets/abraham.png" alt=""  width="100%"/>
            <h4 style={{marginTop:"5px"}}><b>{"亚伯拉罕·特纳·曼里克教授"}</b></h4>
            <h5><em>{"世界钢琴教师协会主席（西班牙）-作曲"}</em></h5>
            </div>
        <div style={{position:"absolute"}}>
            <img className="jury-flag" alt="" src="/assets/spain-flag.png" /></div>
        <div className="jury-text"><p>
        作为世界钢琴教师协会西班牙作曲家协会、伊比利亚作曲家协会协会、加泰罗尼亚作曲家协会协会和 Autores y Editores 协会主席，
             亚伯拉罕·特纳·曼里克教授活跃于作曲领域。 2021年和2022年，他作为客座作曲家参加了两个国际比赛和音乐节：WPTA西班牙和MIMAS音乐节比赛。 许多参赛者演奏了他的作品。
             在这两项比赛中，他的一首作品的最佳表演者都获得了财政奖学金。 2021 年，<i>达芬奇</i>出版社完成了出版曼里克教授所有作品的雄心勃勃的项目，产生了 7 部精美的作品
             卷包括钢琴作品、钢琴四手联弹、钢琴协奏曲和管弦乐队作品。 42，清唱剧“基督在十字架上的最后七句话” Op.40，室内乐作品，采用单簧管和一首谎言。 这条新闻也刊登在了广泛的采访中
             2022 年 1 月的 Ritmo 杂志最终确定了自 2017 年以来发起的创意理念。
        </p>
        <p>2018年4月，曼里克教授被任命为WPTA-西班牙-作曲（世界钢琴教师协会）主席。 WPTA 是一个遍布 30 多个国家的协会，每年组织会议、钢琴、作曲和室内乐会议
         音乐比赛，以及独奏会和大师班。 曼里克教授担任2019年WPTA-西班牙第一届国际作曲大赛评委会主席。2017年至2018年间，曼里克教授举办了多场独奏会，演奏了18首钢琴作品
         这张由<i>达芬奇</i>出版的CD由他本人演奏的几首作品组成。
        </p>
        <p>在不忽视音乐会方面的同时，还值得一提的是由该机构董事兼创始人 Nati Ballarin 邀请的第三届 Villa de Xabia 国际音乐节（阿利坎特）举办的闭幕音乐会。 对作曲家的钢琴作品感兴趣，她创作了
         第六届 Villa de Xabia 国际钢琴比赛荣获“Abraham Tena Manrique”奖，她也是 2018 年该比赛的创始人和总监。
        </p>
        <p>教授。 曼里克的作品除了解释和编辑方面外，还包括西班牙国家交响乐团和合唱团（OCNE）钢琴家塞尔吉奥·埃斯佩霍（Sergio Espejo）委托创作的《Cantata Las Siete Words de Cristo en la Cruz》等作品。 它出现在一场卫星音乐会上
         OCNE 每年在马德里礼堂举办一次，作曲家在那里表演了他的塞内卡八重奏作品。 经典赛 (RNE) 于 2016 年 3 月 1 日举行。
        </p>
         <p> 近日，大师特纳·曼里克为日本唱片公司<i>达芬奇</i>录制了他的所有钢琴独奏作品。 感谢 Pianoservei，在<i>巴塞罗那礼堂</i>录制的长会议中使用<i>Steinway Gran Cola D274</i>录制。
         50 件作品将在几个月内出现在 3 张 CD 上。 2023 年秋冬，他将举办一些独奏会来展示许多作品。 Manrique 教授现任 WPTA-SPAIN-COMPOSITION 主席。
         WPTA 是一个世界各地的协会，组织年度会议、钢琴演奏比赛、作曲和室内乐，以及独奏会和大师班。</p></div>
     </div>


    <div style={{display:'block', marginBottom:"100px"}}>
        
        <div className="jury-text" style={{marginLeft:"0px",marginRight:"4%"}}>
        <p>
            梅丽莎·索罗汉女士出生于美国，目前居住在香港，担任表演者和声乐教练。
         </p>
         <p>梅丽莎毕业于美国俄亥俄州克利夫兰克利夫兰音乐学院，不久后开始在郊区的一所音乐学校任教。 2012 年，她被任命为俄亥俄州坎顿马龙大学声乐兼职讲师
             她继续在那里任教直到2017年。
         </p>
         <p>在此期间，她在俄亥俄州阿克伦市的阿克伦大学完成了声乐音乐硕士学位。 2017年，她移居香港，在香港M.Int Academy担任声乐教学职位。 她目前是世界家庭俱乐部的剧组演员。</p>
         <p>在学习期间，梅丽莎有机会通过参加意大利锡耶纳的 Sessione Senese per La Musica e L'Arte 在意大利和瑞士唱歌。 她还是 Resonanz Opera 夏季项目（俄亥俄州威洛比）的参与者
             并参加了南丁格尔歌剧院（俄亥俄州哈德逊）的大师班。</p>
         <p>住在克利夫兰时，梅丽莎是一名定期演出的音乐家，与五大湖轻歌剧院一起演唱，并在各个教堂和犹太教堂中担任合唱职务。 在舞台上，她扮演了<i>Amahl</i>中的母亲和<i>夜间访客</i>，以及<i>The Medium</i>中的Baba，
         和<i>Gallantry</i>中的播音员，均来自阿克伦大学歌剧院。 梅丽莎还很高兴与她的学生一起在马龙歌剧院的各种作品中表演。 她在《Suor Angelica》中扮演La Zia Principessa，在《Le Nozze Di Figaro》中扮演Marcellina，
         和《魔笛》中的第一夫人。 她最近在南丁格尔歌剧院的《某种疯狂》中饰演德罗什夫人。</p>
         <p>Melissa 目前是香港一名忙碌的表演者，在世界家庭俱乐部和 Dove Tails Theatre 的儿童剧院工作。 她还定期与谋杀悬疑剧团 Harbour Secrets 一起表演。 她最近还荣幸地主持了香港妇女一周年聚会。
             梅丽莎还在名为 Seaside Arms 的流行/摇滚翻唱乐队中演唱，并继续与圣约翰大教堂晚祷合唱团、香港巴赫合唱团和塞西莉安歌手合唱团一起演唱古典歌曲。</p>
         </div>

        <div className="jury-profile" style={{position:"relative"}}>
            <div style={{position:"absolute"}}>
                <img className="jury-flag" alt=""  src="/assets/usa-flag.jpg" /></div>
            <img src="/assets/melissa.png"  alt="" width="100%"/>
            <h4 style={{marginTop:"5px"}}><b>{"梅丽莎·索罗汉女士"}</b></h4>
            <h5><em>{"歌剧歌手和大学声乐导师"}</em></h5>
            </div>
            
    </div>



    <div style={{display:'block', marginBottom:"100px"}}>
        <div className="jury-profile">
            <img src="/assets/antonio.jpg" alt=""  width="100%"/>
            <h4 style={{marginTop:"5px"}}><b>{"安东尼奥·弗兰克·贾迪利诺·马西埃尔博士"}</b></h4>
            <h5><em>{"首席长号和音乐教育家"}</em></h5>
            </div>
        <div style={{position:"absolute"}}>
            <img className="jury-flag" alt="" src="/assets/italy-flag.png" /></div>
        <div className="jury-text"><p>
        弗兰克六岁时开始跟随他的兄弟学习音乐，兄弟教他滑音长号和次中音号。 他在福塔莱萨（巴西）的“Piamarta”学院与“Dona Luiza Tavora”乐队一起演奏多年，
            在那里他有机会参加各种国际管乐团比赛。
            </p>
            <p>后来弗兰克来到欧洲，在宗座慈幼会大学学习哲学，并取得音乐治疗硕士学位。</p>
             <p>Frank 还在布雷西亚（意大利）的圣塞西莉亚基金会学习，师从 M. Paderni 学习钢琴，师从 Gregorian M. Longhini，师从 G. Facchinetti 学习作曲。
             他开始在布雷西亚的“Luca Marenzio”音乐学院学习长号，并于 2007 年师从著名的 G. Delmonte 获得长号专业（学士和硕士学位）。</p>
             <p>他在“I solisti Europei”管弦乐团中担任独奏长号，并作为“Mascoulisse”长号四重奏的成员。 他赢得了“佩斯卡拉交响乐团”的第一次长号试音
                 他在那里工作过，在一个不稳定的重要程序中运行。 他还在多个歌剧季中与“Societa Filarmonica di Udine”和“Orchestra Regionale Marchigiana”合作。
             </p>
             <p>弗兰克赢得了“Stage Entertainment-Italy”公司的试镜，他是该公司的长号和大号首席，并在“妈妈咪呀”、“美女与野兽”和“姐妹行动”（2009、2010 季和 2011）。
                 2010年、2011年、2012年，他作为塞阿拉政府州（巴西）的贵宾，邀请他参加第二届、第三届和第四届“Forum dos trombonistas Cearences”，并在那里教授各种大师班。</p>
             <p>这些年来，他还为塞拉州的新长号“学士”课程编写了教育项目：为同一州的顶尖大学编写的项目。 该项目目前存放在
                 “SECITECE”（科学技术秘书处）正在批准和应用中。 2012年，他作为特邀嘉宾参加了“巴西长号演奏家节”并参加了ABT（巴西长号演奏家协会）首届研讨会。
             </p>
             <p>2011 年，他在“意大利 Istituo Musicale Pareggiato della Valle d'Aostoa”获得“Diploma accademico di Il livello (D.M.A)”，研究巴西背景和巴西流行音乐，并以优异的成绩毕业。</p>
             <p>在那两年里，他有机会师从：Stefano Viola（自由职业者和教师）、Toby Oft（波士顿交响乐团）、Andrea Bandini（Suisse Romande）、Vincet Lepape（雷焦都灵剧院）、Giuseppe Grandi（ 斯卡拉歌剧院），法比亚诺·费伦扎尼
                 （马吉奥·菲奥伦蒂诺）、伊恩·布斯菲尔德（维也纳爱乐乐团）、马西莫·拉·罗莎（克利夫兰交响乐团）、斯特凡·舒尔茨（柏林爱乐乐团）。
             </p>
             <p>弗兰克在欧洲、巴西和中东开展了激烈的教学和音乐活动。 2011/2012 年，他担任巴西福塔莱萨“Faculdade Tecnica Darcy Ribeiro”的长号老师，以及“Edward Said 国家音乐学院和比尔泽特大学”的铜管老师。
                 从2013年9月到2014年5月。
             </p>
             <p>Frank 还于 2015 年以一篇有关巴西音乐的论文获得了葡萄牙“埃武拉大学”的高等研究文凭（博士级）。 2017年11月，他获得基耶蒂-佩斯卡拉大学“G.D. Annunzio”哲学科学硕士学位
                 根据凯瑟琳·马拉布的解释，撰写了一篇关于黑格尔和康德的论文。
             </p>
             <p>2019 年，他与 Mimesis 合作编辑并出版了凯瑟琳·马拉布 (Catherine Malabou) 部分作品的译本，标题为 <i>Avvenire e dolore trascendentale</i>，并与 Meltemi <i>Divenire Forma 合作出版。 同一作者的 Epigenesi e razionalita</i>。
             此外，他还在索邦大学攻读意大利研究博士学位，研究领域涉及生物学、神经生物学、神经科学和艺术形式（如音乐）等不同领域。</p>
             <p>他现在是<i>Corelli室内乐团</i>的首席长号和独奏家，也是Chorocarioca五重奏的重要成员。</p>
             </div>
    </div>

    
    <div style={{display:'block', marginBottom:"100px"}}>
       
        <div className="jury-text" style={{marginLeft:"0px",marginRight:"4%"}}><p>
            亚历山大·特赫 (Alexandre Teheux) 1959 年出生于巴黎。在费德里克·德尔沃 (Federic Delvaux) 的私人课程后，他开始在列日格雷特里音乐学院 (Academie Gretry) 跟随珍妮·伯纳德·纳彭 (Jeanne Bernard-Knapen) 学习音乐。</p>
             <p>后来他在列日音乐学院师从 Chantal Bohets 和 Marcelle Mercenier 学习。 他还向 Robert Bleser 学习伴奏，向 Berthe Di Vito-Delvaux 学习和声，向 Guy Luypaerts 学习实用和声，
                 与塞西尔·埃夫拉德 (Cecile Evrard) 和让-皮埃尔·佩维翁 (Jean-Pierre Peuvion) 合作的室内乐。
             </p>
             <p>1987 年至 1988 年，比利时政府的一笔资助使他有机会在华沙肖邦学院接受 Regina Smendzianka、Andrzej Dutkiewicz 和 Janusz Grobelny 的授课。</p>
             <p>他定期返回那里参加夏季课程，在那里他发现了肖邦祖国的氛围和文化。</p>
             <p>亚历山大·特赫 (Alexandre Teheux) 是格雷特里学院 (Academie Gretry) 的伴奏家和钢琴教师，他继续在音乐会上演出，特别是与小提琴家伯努瓦·帕奎 (Benoit Paquay) 合作。</p>
             </div>

        <div className="jury-profile" style={{position:"relative"}}>
            <div style={{position:"absolute"}}>
                <img className="jury-flag" alt="" src="/assets/belgium-flag.jpg" /></div>
            <img src="/assets/alexandre.jpg" alt=""  width="100%"/>
            <h4 style={{marginTop:"5px"}}><b>{"亚历山大·特赫先生"}</b></h4>
            <h5><em>{"钢琴家、音乐教育家"}</em></h5>
        </div>

    </div>

      </div>
      </div>
    );
  }
  }
  

export default IYAOCNJury;
