import React from 'react'
import { Navbar,Nav,NavDropdown,Form,FormControl,Button,Dropdown } from 'react-bootstrap'

const menu = {
    "en":{
        "home":"Home", "about":"About", "magazine":"MAGAZINE & GALLERY", "category":"Category", "apply":"Apply", "awards":"Awards","ourproduct":"Our Products", "dropdown":"Drop Down","rtprice":"Real-time Prices","fnews":"Financial News","histprice":"Historical Data","riskman":"Risk Management","traderecord":"Trade Record","memberrank":"Memberships", "terms":"Rules & Regulations","jury":"Jury","contact":"Contact","winners":"Winners"
    },
    "zh":{
        "home":"主頁", "quote":"實時報價", "aboutus":"關於我們", "awards":"Awards", "ourservice":"我們的業務","ourproduct":"我們的產品", "dropdown":"更多","rtprice":"實時市場價格","fnews":"財經新聞","histprice":"歷史價格圖表","riskman":"風險管理及入市計劃","traderecord":"交易詳情","memberrank":"會員階級制度"
    },
    "cn":{
        "home":"主页", "quote":"实时报价", "aboutus":"关于我们", "awards":"Awards","ourservice":"我们的业务","ourproduct":"我们的产品", "dropdown":"更多","rtprice":"实时市场价格","fnews":"财经新闻","histprice":"历史价格图表","riskman":"风险管理及入市计划","traderecord":"交易详情","memberrank":"会员阶级制度"
    },
}

class NavBarTop extends React.Component{
    constructor(props){
        super(props);
        this.state = {  
          
        };
    
        this.clicknav = this.clicknav.bind(this)

      }
    componentDidMount(){
        document.title="Worldwide Pop and Jazz Music Competition"
        var link1 = document.getElementById('favicon')
        var link2 = document.getElementById('touchicon')
        link1.href="/assets/wpjm-logo.PNG"
        link2.href="/assets/wpjm-logo.PNG"
        this.props.setval({comp:"wpjmc"})
    }
      clicknav(page,pos){
        if(page===this.props.pageSelected){
            document.getElementById(pos).scrollIntoView();
        }else {
            this.props.setval({pageSelected:page,pagepos:pos});           
        } 
      }
//{this.props.companyname[this.props.lang]}
    render(){
        return(
            <div>
                <div className="row">
                    <div className={["col-md-12","fullwidth"].join()}>
                       
                            <Navbar style={{backgroundColor:"rgba(70,30,190,1)",borderBottom: "5px solid #af9dca"}} expand="lg" sticky="top">
                                <Navbar.Brand className="navbar-brand" href="/"><img src="/assets/wpjm-logo.PNG" width="210px" height="75px"/></Navbar.Brand>
                                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                <Navbar.Collapse className="navbar-collapse" id="basic-navbar-nav">
                                    <Nav className="mr-auto">
                                    {/* <Nav.Link style={{fontFamily:"Courgette",fontSize:"1.6rem",color:"white",fontWeight:"bold",marginLeft:"10px"}} href="/wpjmc" onClick={(e)=>{
                                        //this.props.setval({pageSelected:"home"})
                                        }}>{menu[this.props.lang]["home"]}</Nav.Link> */}

                                        {/* <NavDropdown style={{marginLeft:"25px",fontFamily:"Courgette",fontSize:"1.6rem",color:"white",fontWeight:"bold"}} title={menu[this.props.lang]["home"]}>
                                         <NavDropdown.Item href="/wpjmc" >{menu[this.props.lang]["home"]}</NavDropdown.Item>
                                            <NavDropdown.Item href="/wpjmc/magazine" >{menu[this.props.lang]["magazine"]}</NavDropdown.Item>
                                        </NavDropdown> */}

                                    <Nav.Link style={{fontFamily:"Courgette",fontSize:"1.6rem",color:"white",fontWeight:"bold",marginLeft:"10px"}} href="/wpjmc" onClick={(e)=>{
                                            //this.props.setval({pageSelected:"about"})
                                            }}>{menu[this.props.lang]["home"]}</Nav.Link>
                                    <Nav.Link style={{fontFamily:"Courgette",fontSize:"1.6rem",color:"white",fontWeight:"bold",marginLeft:"10px"}} href="/wpjmc/about" onClick={(e)=>{
                                        //this.props.setval({pageSelected:"about"})
                                        }}>{menu[this.props.lang]["about"]}</Nav.Link>
                                    <Nav.Link style={{fontFamily:"Courgette",fontSize:"1.6rem",color:"white",fontWeight:"bold",marginLeft:"10px"}} href="/wpjmc/apply"onClick={(e)=>{
                                        //this.props.setval({pageSelected:"apply"})
                                        }}>{menu[this.props.lang]["apply"]}</Nav.Link>
                                    <Nav.Link style={{fontFamily:"Courgette",fontSize:"1.6rem",color:"white",fontWeight:"bold",marginLeft:"10px"}} href="/wpjmc/category" onClick={(e)=>{
                                        //this.props.setval({pageSelected:"category"})
                                        }}>{menu[this.props.lang]["category"]}</Nav.Link>
                                    <Nav.Link style={{fontFamily:"Courgette",fontSize:"1.6rem",color:"white",fontWeight:"bold",marginLeft:"10px"}} href="/wpjmc/awards" onClick={(e)=>{
                                        //this.props.setval({pageSelected:"awards"})
                                        }}>{menu[this.props.lang]["awards"]}</Nav.Link>
                                    <Nav.Link style={{fontFamily:"Courgette",fontSize:"1.6rem",color:"white",fontWeight:"bold",marginLeft:"10px"}} href="/wpjmc/terms" onClick={(e)=>{
                                        //this.props.setval({pageSelected:"terms"})
                                        }}>{menu[this.props.lang]["terms"]}</Nav.Link>
                                    <Nav.Link style={{fontFamily:"Courgette",fontSize:"1.6rem",color:"white",fontWeight:"bold",marginLeft:"10px"}} href="/wpjmc/jury" onClick={(e)=>{
                                        //this.props.setval({pageSelected:"jury"})
                                        }}>{menu[this.props.lang]["jury"]}</Nav.Link>
                                    <Nav.Link style={{fontFamily:"Courgette",fontSize:"1.6rem",color:"white",fontWeight:"bold",marginLeft:"10px"}} href="/wpjmc/contact" onClick={(e)=>{
                                        //this.props.setval({pageSelected:"jury"})
                                        }}>{menu[this.props.lang]["contact"]}</Nav.Link>
                                    {/* <Nav.Link style={{fontFamily:"Courgette",fontSize:"1.6rem",color:"white",fontWeight:"bold",marginLeft:"10px"}} href="/wpjmc/winners" onClick={(e)=>{
                                        //this.props.setval({pageSelected:"jury"})
                                        }}>{menu[this.props.lang]["winners"]}</Nav.Link> */}

                                    <img src="/assets/yt_logo.PNG" className={"nav-ytlogo"} style={{marginLeft:"20px"}} height={"65px"} alt={"YT_LOGO"} />
                                   
                                   
                                    </Nav>
                                   
                                </Navbar.Collapse>
                            </Navbar>
                         
                    </div>
                </div>
            </div>
        )  
    }
}

export default NavBarTop;