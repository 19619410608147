import pricelist from "./pricelist";

const groupCat = {
    "jazz" :{
        "Vocal Solo" : {
            1:{
            "id":1,"number":"JVS1", "name":"Solo Vocal Junior Group", "des":"Age 12 or below; Any 1 piece under 4 minutes", "price":pricelist["JVS1"],"name-cn":"约翰·汤普森最简单的钢琴课程第一冊","des-cn":"书本中的任何一首曲目"
            },
            2:{
            "id":2,"number":"JVS2", "name":"Solo Vocal Youth Group", "des":"Age 12-18; Any 1 piece under 4 minutes", "price":pricelist["JVS2"],"name-cn":"约翰·汤普森最简单的钢琴课程第二冊","des-cn":"书本中的任何一首曲目"
            },
            3:{
            "id":3,"number":"JVS3", "name":"Solo Vocal Young Artist Group", "des":"Age 18-25; Any 1 piece under 6 minutes", "price":pricelist["JVS3"],"name-cn":"Piano Lesson Made Easy第一冊","des-cn":"书本中的任何一首曲目"
            },
            4:{
            "id":4,"number":"JVS4", "name":"Solo Vocal Artist Group", "des":"Any age; Any 1 piece under 6 minutes", "price":pricelist["JVS4"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
            },
            5:{
            "id":5,"number":"JVS5", "name":"Original Composition Group", "des":"Any age; Applicant's original work under 6 minutes", "price":pricelist["JVS5"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
            },
           
        },   
        "Vocal Duo" : {
            1:{
            "id":1,"number":"JVD1", "name":"Duo Vocal Junior Group", "des":"Age 12 or below; Any 1 piece under 4 minutes", "price":pricelist["JVS1"],"name-cn":"约翰·汤普森最简单的钢琴课程第一冊","des-cn":"书本中的任何一首曲目"
            },
            2:{
            "id":2,"number":"JVD2", "name":"Duo Vocal Youth Group", "des":"Age 12-18; Any 1 piece under 4 minutes", "price":pricelist["JVS2"],"name-cn":"约翰·汤普森最简单的钢琴课程第二冊","des-cn":"书本中的任何一首曲目"
            },
            3:{
            "id":3,"number":"JVD3", "name":"Duo Vocal Young Artist Group", "des":"Age 18-25; Any 1 piece under 6 minutes", "price":pricelist["JVS3"],"name-cn":"Piano Lesson Made Easy第一冊","des-cn":"书本中的任何一首曲目"
            },
            4:{
            "id":4,"number":"JVD4", "name":"Duo Vocal Artist Group", "des":"Any age; Any 1 piece under 6 minutes", "price":pricelist["JVS4"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
            },
            5:{
            "id":5,"number":"JVD5", "name":"Original Composition Group", "des":"Any age; Applicant's original work under 6 minutes", "price":pricelist["JVD5"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
            },
           
        }, 
        "Choir Group" : {
            1:{
            "id":1,"number":"JVG1", "name":"Choir Vocal Junior Group", "des":"Age 12 or below; Any 1 piece under 4 minutes", "price":pricelist["JVS1"],"name-cn":"约翰·汤普森最简单的钢琴课程第一冊","des-cn":"书本中的任何一首曲目"
            },
            2:{
            "id":2,"number":"JVG2", "name":"Choir Vocal Youth Group", "des":"Age 12-18; Any 1 piece under 4 minutes", "price":pricelist["JVS2"],"name-cn":"约翰·汤普森最简单的钢琴课程第二冊","des-cn":"书本中的任何一首曲目"
            },
            3:{
            "id":3,"number":"JVG3", "name":"Choir Vocal Young Artist Group", "des":"Age 18-25; Any 1 piece under 6 minutes", "price":pricelist["JVS3"],"name-cn":"Piano Lesson Made Easy第一冊","des-cn":"书本中的任何一首曲目"
            },
            4:{
            "id":4,"number":"JVG4", "name":"Choir Vocal Artist Group", "des":"Any age; Any 1 piece under 6 minutes", "price":pricelist["JVS4"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
            },  
            5:{
            "id":5,"number":"JVG5", "name":"Original Composition Group", "des":"Any age; Applicant's original work under 6 minutes", "price":pricelist["JVG5"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
            },    
        }, 
        "Solo Instrument" : {
            1:{
            "id":1,"number":"JIS1", "name":"Solo Instrument Junior Group", "des":"Age 12 or below; Any 1 piece under 4 minutes", "price":pricelist["JVS1"],"name-cn":"约翰·汤普森最简单的钢琴课程第一冊","des-cn":"书本中的任何一首曲目"
            },
            2:{
            "id":2,"number":"JIS2", "name":"Solo Instrument Youth Group", "des":"Age 12-18; Any 1 piece under 4 minutes", "price":pricelist["JVS2"],"name-cn":"约翰·汤普森最简单的钢琴课程第二冊","des-cn":"书本中的任何一首曲目"
            },
            3:{
            "id":3,"number":"JIS3", "name":"Solo Instrument Young Artist Group", "des":"Age 18-25; Any 1 piece under 6 minutes", "price":pricelist["JVS3"],"name-cn":"Piano Lesson Made Easy第一冊","des-cn":"书本中的任何一首曲目"
            },
            4:{
            "id":4,"number":"JIS4", "name":"Solo Instrument Artist Group", "des":"Any age; Any 1 piece under 6 minutes", "price":pricelist["JVS4"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
            },
            5:{
            "id":5,"number":"JIS5", "name":"Original Composition Group", "des":"Any age; Applicant's original work under 6 minutes", "price":pricelist["JIS5"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
            },
        }, 
        "Duo Instrument"  : {
            1:{
            "id":1,"number":"JID1", "name":"Duo Instrument Junior Group", "des":"Age 12 or below; Any 1 piece under 4 minutes", "price":pricelist["JVS1"],"name-cn":"约翰·汤普森最简单的钢琴课程第一冊","des-cn":"书本中的任何一首曲目"
            },
            2:{
            "id":2,"number":"JID2", "name":"Duo Instrument Youth Group", "des":"Age 12-18; Any 1 piece under 4 minutes", "price":pricelist["JVS2"],"name-cn":"约翰·汤普森最简单的钢琴课程第二冊","des-cn":"书本中的任何一首曲目"
            },
            3:{
            "id":3,"number":"JID3", "name":"Duo Instrument Young Artist Group", "des":"Age 18-15; Any 1 piece under 6 minutes", "price":pricelist["JVS3"],"name-cn":"Piano Lesson Made Easy第一冊","des-cn":"书本中的任何一首曲目"
            },
            4:{
            "id":4,"number":"JID4", "name":"Duo Instrument Artist Group", "des":"Any age; Any 1 piece under 6 minutes", "price":pricelist["JVS4"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
            },
            5:{
            "id":5,"number":"JID5", "name":"Original Composition Group", "des":"Any age; Applicant's original work under 6 minutes", "price":pricelist["JID5"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
            },
        }, 
        "Instrumental Ensemble"  : {
            1:{
            "id":1,"number":"JIE1", "name":"Instrumental Ensemble Junior Group", "des":"Age 12 or below; Any 1 piece under 4 minutes", "price":pricelist["JVS1"],"name-cn":"约翰·汤普森最简单的钢琴课程第一冊","des-cn":"书本中的任何一首曲目"
            },
            2:{
            "id":2,"number":"JIE2", "name":"Instrumental Ensemble Youth Group", "des":"Age 12-18; Any 1 piece under 4 minutes", "price":pricelist["JVS2"],"name-cn":"约翰·汤普森最简单的钢琴课程第二冊","des-cn":"书本中的任何一首曲目"
            },
            3:{
            "id":3,"number":"JIE3", "name":"Instrumental Ensemble Young Artist Group", "des":"Age 18-25; Any 1 piece under 6 minutes", "price":pricelist["JVS3"],"name-cn":"Piano Lesson Made Easy第一冊","des-cn":"书本中的任何一首曲目"
            },
            4:{
            "id":4,"number":"JIE4", "name":"Instrumental Ensemble Artist Group", "des":"Any age; Any 1 piece under 6 minutes", "price":pricelist["JVS4"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
            }, 
            5:{
            "id":5,"number":"JIG5", "name":"Original Composition Group", "des":"Any age; Applicant's original work under 6 minutes", "price":pricelist["JIG5"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
            },
        }, 
    },
    "countrymusic":{
        "Solo Groups" : {
            1:{
            "id":1,"number":"PCVS1", "name":"Solo Vocal Junior Group", "des":"Age 12 or below; Any 1 piece under 4 minutes", "price":pricelist["PCVS1"],"name-cn":"约翰·汤普森最简单的钢琴课程第一冊","des-cn":"书本中的任何一首曲目"
            },
            2:{
            "id":2,"number":"PCVS2", "name":"Solo Vocal Youth Group", "des":"Age 12-18; Any 1 piece under 4 minutes", "price":pricelist["PCVS2"],"name-cn":"约翰·汤普森最简单的钢琴课程第二冊","des-cn":"书本中的任何一首曲目"
            },
            3:{
            "id":3,"number":"PCVS3", "name":"Solo Vocal Young Artist Group", "des":"Age 18-25; Any 1 piece under 6 minutes", "price":pricelist["PCVS3"],"name-cn":"Piano Lesson Made Easy第一冊","des-cn":"书本中的任何一首曲目"
            },
            4:{
            "id":4,"number":"PCVS4", "name":"Solo Vocal Artist Group", "des":"Any age; Any 1 piece under 6 minutes", "price":pricelist["PCVS4"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
            },
            5:{
            "id":5,"number":"PCIS1", "name":"Solo Instrument Junior Group", "des":"Age 12 or below; Any 1 piece under 4 minutes", "price":pricelist["PCIS1"],"name-cn":"约翰·汤普森最简单的钢琴课程第一冊","des-cn":"书本中的任何一首曲目"
            },
            6:{
            "id":6,"number":"PCIS2", "name":"Solo Instrument Youth Group", "des":"Age 12-18; Any 1 piece under 4 minutes", "price":pricelist["PCIS2"],"name-cn":"约翰·汤普森最简单的钢琴课程第二冊","des-cn":"书本中的任何一首曲目"
            },
            7:{
            "id":7,"number":"PCIS3", "name":"Solo Instrument Young Artist Group", "des":"Age 18-25; Any 1 piece under 6 minutes", "price":pricelist["PCIS3"],"name-cn":"Piano Lesson Made Easy第一冊","des-cn":"书本中的任何一首曲目"
            },
            8:{
            "id":8,"number":"PCIS4", "name":"Solo Instrument Artist Group", "des":"Any age; Any 1 piece under 6 minutes", "price":pricelist["PCIS4"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
            },
            9:{
                "id":9,"number":"PCOS1", "name":"Solo Vocal Original Composition Group", "des":"Any age; Applicant's original work under 6 minutes", "price":pricelist["PCOS1"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
                },
            10:{
                "id":10,"number":"PCOS2", "name":"Solo Instrument Original Composition Group", "des":"Any age; Applicant's original work under 6 minutes", "price":pricelist["PCOS2"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
                },
           
        }, 
        "Duo Groups" : {
            1:{
            "id":1,"number":"PCVD1", "name":"Duo Vocal Junior Group", "des":"Age 12 or below; Any 1 piece under 4 minutes", "price":pricelist["PCVD1"],"name-cn":"约翰·汤普森最简单的钢琴课程第一冊","des-cn":"书本中的任何一首曲目"
            },
            2:{
            "id":2,"number":"PCVD2", "name":"Duo Vocal Youth Group", "des":"Age 12-18; Any 1 piece under 4 minutes", "price":pricelist["PCVD2"],"name-cn":"约翰·汤普森最简单的钢琴课程第二冊","des-cn":"书本中的任何一首曲目"
            },
            3:{
            "id":3,"number":"PCVD3", "name":"Duo Vocal Young Artist Group", "des":"Age 18-25; Any 1 piece under 6 minutes", "price":pricelist["PCVD3"],"name-cn":"Piano Lesson Made Easy第一冊","des-cn":"书本中的任何一首曲目"
            },
            4:{
            "id":4,"number":"PCVD4", "name":"Duo Vocal Artist Group", "des":"Any age; Any 1 piece under 6 minutes", "price":pricelist["PCVD4"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
            },
            5:{
            "id":5,"number":"PCID1", "name":"Duo Instrument Junior Group", "des":"Age 12 or below; Any 1 piece under 4 minutes", "price":pricelist["PCID1"],"name-cn":"约翰·汤普森最简单的钢琴课程第一冊","des-cn":"书本中的任何一首曲目"
            },
            6:{
            "id":6,"number":"PCID2", "name":"Duo Instrument Youth Group", "des":"Age 12-18; Any 1 piece under 4 minutes", "price":pricelist["PCID2"],"name-cn":"约翰·汤普森最简单的钢琴课程第二冊","des-cn":"书本中的任何一首曲目"
            },
            7:{
            "id":7,"number":"PCID3", "name":"Duo Instrument Young Artist Group", "des":"Age 18-25; Any 1 piece under 6 minutes", "price":pricelist["PCID3"],"name-cn":"Piano Lesson Made Easy第一冊","des-cn":"书本中的任何一首曲目"
            },
            8:{
            "id":8,"number":"PCID4", "name":"Duo Instrument Artist Group", "des":"Any age; Any 1 piece under 6 minutes", "price":pricelist["PCID4"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
            },
            9:{
                "id":9,"number":"PCOD1", "name":"Duo Vocal Original Composition Group", "des":"Any age; Applicant's original work under 6 minutes", "price":pricelist["PCOD1"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
                },
            10:{
                "id":10,"number":"PCOD2", "name":"Duo Instrument Original Composition Group", "des":"Any age; Applicant's original work under 6 minutes", "price":pricelist["PCOD2"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
                },
           
        }, 
        "Ensemble/Choir Groups" : {
            1:{
            "id":1,"number":"PCVG1", "name":"Ensemble Vocal Junior Group", "des":"Age 12 or below; Any 1 piece under 4 minutes", "price":pricelist["PCVD1"],"name-cn":"约翰·汤普森最简单的钢琴课程第一冊","des-cn":"书本中的任何一首曲目"
            },
            2:{
            "id":2,"number":"PCVG2", "name":"Ensemble Vocal Youth Group", "des":"Age 12-18; Any 1 piece under 4 minutes", "price":pricelist["PCVD2"],"name-cn":"约翰·汤普森最简单的钢琴课程第二冊","des-cn":"书本中的任何一首曲目"
            },
            3:{
            "id":3,"number":"PCVG3", "name":"Ensemble Vocal Young Artist Group", "des":"Age 18-25; Any 1 piece under 6 minutes", "price":pricelist["PCVD3"],"name-cn":"Piano Lesson Made Easy第一冊","des-cn":"书本中的任何一首曲目"
            },
            4:{
            "id":4,"number":"PCVG4", "name":"Ensemble Vocal Artist Group", "des":"Any age; Any 1 piece under 6 minutes", "price":pricelist["PCVD4"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
            },
            5:{
            "id":5,"number":"PCIG1", "name":"Ensemble Instrument Junior Group", "des":"Age 12 or below; Any 1 piece under 4 minutes", "price":pricelist["PCID1"],"name-cn":"约翰·汤普森最简单的钢琴课程第一冊","des-cn":"书本中的任何一首曲目"
            },
            6:{
            "id":6,"number":"PCIG2", "name":"Ensemble Instrument Youth Group", "des":"Age 12-18; Any 1 piece under 4 minutes", "price":pricelist["PCID2"],"name-cn":"约翰·汤普森最简单的钢琴课程第二冊","des-cn":"书本中的任何一首曲目"
            },
            7:{
            "id":7,"number":"PCIG3", "name":"Ensemble Instrument Young Artist Group", "des":"Age 18-25; Any 1 piece under 6 minutes", "price":pricelist["PCID3"],"name-cn":"Piano Lesson Made Easy第一冊","des-cn":"书本中的任何一首曲目"
            },
            8:{
            "id":8,"number":"PCIG4", "name":"Ensemble Instrument Artist Group", "des":"Any age; Any 1 piece under 6 minutes", "price":pricelist["PCID4"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
            },
            9:{
                "id":9,"number":"PCOG1", "name":"Choir Vocal Original Composition Group", "des":"Any age; Applicant's original work under 6 minutes", "price":pricelist["PCOG1"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
                },
            10:{
                "id":10,"number":"PCOG2", "name":"Ensemble Instrument Original Composition Group", "des":"Any age; Applicant's original work under 6 minutes", "price":pricelist["PCOG2"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
                },
           
        }, 
    },
    "r&b":{
        "Solo Groups" : {
            1:{
            "id":1,"number":"PRVS1", "name":"Solo Vocal Junior Group", "des":"Age 12 or below; Any 1 piece under 4 minutes", "price":pricelist["PRVS1"],"name-cn":"约翰·汤普森最简单的钢琴课程第一冊","des-cn":"书本中的任何一首曲目"
            },
            2:{
            "id":2,"number":"PRVS2", "name":"Solo Vocal Youth Group", "des":"Age 12-18; Any 1 piece under 4 minutes", "price":pricelist["PRVS2"],"name-cn":"约翰·汤普森最简单的钢琴课程第二冊","des-cn":"书本中的任何一首曲目"
            },
            3:{
            "id":3,"number":"PRVS3", "name":"Solo Vocal Young Artist Group", "des":"Age 18-25; Any 1 piece under 6 minutes", "price":pricelist["PRVS3"],"name-cn":"Piano Lesson Made Easy第一冊","des-cn":"书本中的任何一首曲目"
            },
            4:{
            "id":4,"number":"PRVS4", "name":"Solo Vocal Artist Group", "des":"Any age; Any 1 piece under 6 minutes", "price":pricelist["PRVS4"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
            },
            5:{
            "id":5,"number":"PRIS1", "name":"Solo Instrument Junior Group", "des":"Age 12 or below; Any 1 piece under 4 minutes", "price":pricelist["PRIS1"],"name-cn":"约翰·汤普森最简单的钢琴课程第一冊","des-cn":"书本中的任何一首曲目"
            },
            6:{
            "id":6,"number":"PRIS2", "name":"Solo Instrument Youth Group", "des":"Age 12-18; Any 1 piece under 4 minutes", "price":pricelist["PRIS2"],"name-cn":"约翰·汤普森最简单的钢琴课程第二冊","des-cn":"书本中的任何一首曲目"
            },
            7:{
            "id":7,"number":"PRIS3", "name":"Solo Instrument Young Artist Group", "des":"Age 18-25; Any 1 piece under 6 minutes", "price":pricelist["PRIS3"],"name-cn":"Piano Lesson Made Easy第一冊","des-cn":"书本中的任何一首曲目"
            },
            8:{
            "id":8,"number":"PRIS4", "name":"Solo Instrument Artist Group", "des":"Any age; Any 1 piece under 6 minutes", "price":pricelist["PRIS4"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
            },
            9:{
                "id":9,"number":"PROS1", "name":"Solo Vocal Original Composition Group", "des":"Any age; Applicant's original work under 6 minutes", "price":pricelist["PROS1"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
                },
            10:{
                "id":10,"number":"PROS2", "name":"Solo Instrument Original Composition Group", "des":"Any age; Applicant's original work under 6 minutes", "price":pricelist["PROS2"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
                },
           
        }, 
        "Duo Groups" : {
            1:{
            "id":1,"number":"PRVD1", "name":"Duo Vocal Junior Group", "des":"Age 12 or below; Any 1 piece under 4 minutes", "price":pricelist["PRVD1"],"name-cn":"约翰·汤普森最简单的钢琴课程第一冊","des-cn":"书本中的任何一首曲目"
            },
            2:{
            "id":2,"number":"PRVD2", "name":"Duo Vocal Youth Group", "des":"Age 12-18; Any 1 piece under 4 minutes", "price":pricelist["PRVD2"],"name-cn":"约翰·汤普森最简单的钢琴课程第二冊","des-cn":"书本中的任何一首曲目"
            },
            3:{
            "id":3,"number":"PRVD3", "name":"Duo Vocal Young Artist Group", "des":"Age 18-25; Any 1 piece under 6 minutes", "price":pricelist["PRVD3"],"name-cn":"Piano Lesson Made Easy第一冊","des-cn":"书本中的任何一首曲目"
            },
            4:{
            "id":4,"number":"PRVD4", "name":"Duo Vocal Artist Group", "des":"Any age; Any 1 piece under 6 minutes", "price":pricelist["PRVD4"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
            },
            5:{
            "id":5,"number":"PRID1", "name":"Duo Instrument Junior Group", "des":"Age 12 or below; Any 1 piece under 4 minutes", "price":pricelist["PRID1"],"name-cn":"约翰·汤普森最简单的钢琴课程第一冊","des-cn":"书本中的任何一首曲目"
            },
            6:{
            "id":6,"number":"PRID2", "name":"Duo Instrument Youth Group", "des":"Age 12-18; Any 1 piece under 4 minutes", "price":pricelist["PRID2"],"name-cn":"约翰·汤普森最简单的钢琴课程第二冊","des-cn":"书本中的任何一首曲目"
            },
            7:{
            "id":7,"number":"PRID3", "name":"Duo Instrument Young Artist Group", "des":"Age 18-25; Any 1 piece under 6 minutes", "price":pricelist["PRID3"],"name-cn":"Piano Lesson Made Easy第一冊","des-cn":"书本中的任何一首曲目"
            },
            8:{
            "id":8,"number":"PRID4", "name":"Duo Instrument Artist Group", "des":"Any age; Any 1 piece under 6 minutes", "price":pricelist["PRID4"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
            },
            9:{
                "id":9,"number":"PROD1", "name":"Duo Vocal Original Composition Group", "des":"Any age; Applicant's original work under 6 minutes", "price":pricelist["PROD1"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
                },
            10:{
                "id":10,"number":"PROD2", "name":"Duo Instrument Original Composition Group", "des":"Any age; Applicant's original work under 6 minutes", "price":pricelist["PROD2"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
                },
           
        }, 
        "Ensemble/Choir Groups" : {
            1:{
            "id":1,"number":"PRVG1", "name":"Ensemble Vocal Junior Group", "des":"Age 12 or below; Any 1 piece under 4 minutes", "price":pricelist["PRVD1"],"name-cn":"约翰·汤普森最简单的钢琴课程第一冊","des-cn":"书本中的任何一首曲目"
            },
            2:{
            "id":2,"number":"PRVG2", "name":"Ensemble Vocal Youth Group", "des":"Age 12-18; Any 1 piece under 4 minutes", "price":pricelist["PRVD2"],"name-cn":"约翰·汤普森最简单的钢琴课程第二冊","des-cn":"书本中的任何一首曲目"
            },
            3:{
            "id":3,"number":"PRVG3", "name":"Ensemble Vocal Young Artist Group", "des":"Age 18-25; Any 1 piece under 6 minutes", "price":pricelist["PRVD3"],"name-cn":"Piano Lesson Made Easy第一冊","des-cn":"书本中的任何一首曲目"
            },
            4:{
            "id":4,"number":"PRVG4", "name":"Ensemble Vocal Artist Group", "des":"Any age; Any 1 piece under 6 minutes", "price":pricelist["PRVD4"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
            },
            5:{
            "id":5,"number":"PRIG1", "name":"Ensemble Instrument Junior Group", "des":"Age 12 or below; Any 1 piece under 4 minutes", "price":pricelist["PRID1"],"name-cn":"约翰·汤普森最简单的钢琴课程第一冊","des-cn":"书本中的任何一首曲目"
            },
            6:{
            "id":6,"number":"PRIG2", "name":"Ensemble Instrument Youth Group", "des":"Age 12-18; Any 1 piece under 4 minutes", "price":pricelist["PRID2"],"name-cn":"约翰·汤普森最简单的钢琴课程第二冊","des-cn":"书本中的任何一首曲目"
            },
            7:{
            "id":7,"number":"PRIG3", "name":"Ensemble Instrument Young Artist Group", "des":"Age 18-25; Any 1 piece under 6 minutes", "price":pricelist["PRID3"],"name-cn":"Piano Lesson Made Easy第一冊","des-cn":"书本中的任何一首曲目"
            },
            8:{
            "id":8,"number":"PRIG4", "name":"Ensemble Instrument Artist Group", "des":"Any age; Any 1 piece under 6 minutes", "price":pricelist["PRID4"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
            },
            9:{
                "id":9,"number":"PROG1", "name":"Choir Vocal Original Composition Group", "des":"Any age; Applicant's original work under 6 minutes", "price":pricelist["PROG1"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
                },
            10:{
                "id":10,"number":"PROG2", "name":"Ensemble Instrument Original Composition Group", "des":"Any age; Applicant's original work under 6 minutes", "price":pricelist["PROG2"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
                },
           
        }, 
    },
    "pop":{
        "Solo Groups" : {
            1:{
            "id":1,"number":"PPVS1", "name":"Solo Vocal Junior Group", "des":"Age 12 or below; Any 1 piece under 4 minutes", "price":pricelist["PPVS1"],"name-cn":"约翰·汤普森最简单的钢琴课程第一冊","des-cn":"书本中的任何一首曲目"
            },
            2:{
            "id":2,"number":"PPVS2", "name":"Solo Vocal Youth Group", "des":"Age 12-18; Any 1 piece under 4 minutes", "price":pricelist["PPVS2"],"name-cn":"约翰·汤普森最简单的钢琴课程第二冊","des-cn":"书本中的任何一首曲目"
            },
            3:{
            "id":3,"number":"PPVS3", "name":"Solo Vocal Young Artist Group", "des":"Age 18-25; Any 1 piece under 6 minutes", "price":pricelist["PPVS3"],"name-cn":"Piano Lesson Made Easy第一冊","des-cn":"书本中的任何一首曲目"
            },
            4:{
            "id":4,"number":"PPVS4", "name":"Solo Vocal Artist Group", "des":"Any age; Any 1 piece under 6 minutes", "price":pricelist["PPVS4"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
            },
            5:{
            "id":5,"number":"PPIS1", "name":"Solo Instrument Junior Group", "des":"Age 12 or below; Any 1 piece under 4 minutes", "price":pricelist["PPIS1"],"name-cn":"约翰·汤普森最简单的钢琴课程第一冊","des-cn":"书本中的任何一首曲目"
            },
            6:{
            "id":6,"number":"PPIS2", "name":"Solo Instrument Youth Group", "des":"Age 12-18; Any 1 piece under 4 minutes", "price":pricelist["PPIS2"],"name-cn":"约翰·汤普森最简单的钢琴课程第二冊","des-cn":"书本中的任何一首曲目"
            },
            7:{
            "id":7,"number":"PPIS3", "name":"Solo Instrument Young Artist Group", "des":"Age 18-25; Any 1 piece under 6 minutes", "price":pricelist["PPIS3"],"name-cn":"Piano Lesson Made Easy第一冊","des-cn":"书本中的任何一首曲目"
            },
            8:{
            "id":8,"number":"PPIS4", "name":"Solo Instrument Artist Group", "des":"Any age; Any 1 piece under 6 minutes", "price":pricelist["PPIS4"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
            },
            9:{
                "id":9,"number":"PPOS1", "name":"Solo Vocal Original Composition Group", "des":"Any age; Applicant's original work under 6 minutes", "price":pricelist["PPOS1"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
                },
            10:{
                "id":10,"number":"PPOS2", "name":"Solo Instrument Original Composition Group", "des":"Any age; Applicant's original work under 6 minutes", "price":pricelist["PPOS2"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
                },
           
        }, 
        "Duo Groups" : {
            1:{
            "id":1,"number":"PPVD1", "name":"Duo Vocal Junior Group", "des":"Age 12 or below; Any 1 piece under 4 minutes", "price":pricelist["PPVD1"],"name-cn":"约翰·汤普森最简单的钢琴课程第一冊","des-cn":"书本中的任何一首曲目"
            },
            2:{
            "id":2,"number":"PPVD2", "name":"Duo Vocal Youth Group", "des":"Age 12-18; Any 1 piece under 4 minutes", "price":pricelist["PPVD2"],"name-cn":"约翰·汤普森最简单的钢琴课程第二冊","des-cn":"书本中的任何一首曲目"
            },
            3:{
            "id":3,"number":"PPVD3", "name":"Duo Vocal Young Artist Group", "des":"Age 18-25; Any 1 piece under 6 minutes", "price":pricelist["PPVD3"],"name-cn":"Piano Lesson Made Easy第一冊","des-cn":"书本中的任何一首曲目"
            },
            4:{
            "id":4,"number":"PPVD4", "name":"Duo Vocal Artist Group", "des":"Any age; Any 1 piece under 6 minutes", "price":pricelist["PPVD4"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
            },
            5:{
            "id":5,"number":"PPID1", "name":"Duo Instrument Junior Group", "des":"Age 12 or below; Any 1 piece under 4 minutes", "price":pricelist["PPID1"],"name-cn":"约翰·汤普森最简单的钢琴课程第一冊","des-cn":"书本中的任何一首曲目"
            },
            6:{
            "id":6,"number":"PPID2", "name":"Duo Instrument Youth Group", "des":"Age 12-18; Any 1 piece under 4 minutes", "price":pricelist["PPID2"],"name-cn":"约翰·汤普森最简单的钢琴课程第二冊","des-cn":"书本中的任何一首曲目"
            },
            7:{
            "id":7,"number":"PPID3", "name":"Duo Instrument Young Artist Group", "des":"Age 18-25; Any 1 piece under 6 minutes", "price":pricelist["PPID3"],"name-cn":"Piano Lesson Made Easy第一冊","des-cn":"书本中的任何一首曲目"
            },
            8:{
            "id":8,"number":"PPID4", "name":"Duo Instrument Artist Group", "des":"Any age; Any 1 piece under 6 minutes", "price":pricelist["PPID4"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
            },
            9:{
                "id":9,"number":"PPOD1", "name":"Duo Vocal Original Composition Group", "des":"Any age; Applicant's original work under 6 minutes", "price":pricelist["PPOD1"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
                },
            10:{
                "id":10,"number":"PPOD2", "name":"Duo Instrument Original Composition Group", "des":"Any age; Applicant's original work under 6 minutes", "price":pricelist["PPOD2"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
                },
           
        }, 
        "Ensemble/Choir Groups" : {
            1:{
            "id":1,"number":"PPVG1", "name":"Ensemble Vocal Junior Group", "des":"Age 12 or below; Any 1 piece under 4 minutes", "price":pricelist["PPVD1"],"name-cn":"约翰·汤普森最简单的钢琴课程第一冊","des-cn":"书本中的任何一首曲目"
            },
            2:{
            "id":2,"number":"PPVG2", "name":"Ensemble Vocal Youth Group", "des":"Age 12-18; Any 1 piece under 4 minutes", "price":pricelist["PPVD2"],"name-cn":"约翰·汤普森最简单的钢琴课程第二冊","des-cn":"书本中的任何一首曲目"
            },
            3:{
            "id":3,"number":"PPVG3", "name":"Ensemble Vocal Young Artist Group", "des":"Age 18-25; Any 1 piece under 6 minutes", "price":pricelist["PPVD3"],"name-cn":"Piano Lesson Made Easy第一冊","des-cn":"书本中的任何一首曲目"
            },
            4:{
            "id":4,"number":"PPVG4", "name":"Ensemble Vocal Artist Group", "des":"Any age; Any 1 piece under 6 minutes", "price":pricelist["PPVD4"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
            },
            5:{
            "id":5,"number":"PPIG1", "name":"Ensemble Instrument Junior Group", "des":"Age 12 or below; Any 1 piece under 4 minutes", "price":pricelist["PPID1"],"name-cn":"约翰·汤普森最简单的钢琴课程第一冊","des-cn":"书本中的任何一首曲目"
            },
            6:{
            "id":6,"number":"PPIG2", "name":"Ensemble Instrument Youth Group", "des":"Age 12-18; Any 1 piece under 4 minutes", "price":pricelist["PPID2"],"name-cn":"约翰·汤普森最简单的钢琴课程第二冊","des-cn":"书本中的任何一首曲目"
            },
            7:{
            "id":7,"number":"PPIG3", "name":"Ensemble Instrument Young Artist Group", "des":"Age 18-25; Any 1 piece under 6 minutes", "price":pricelist["PPID3"],"name-cn":"Piano Lesson Made Easy第一冊","des-cn":"书本中的任何一首曲目"
            },
            8:{
            "id":8,"number":"PPIG4", "name":"Ensemble Instrument Artist Group", "des":"Any age; Any 1 piece under 6 minutes", "price":pricelist["PPID4"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
            },
            9:{
                "id":9,"number":"PPOG1", "name":"Choir Vocal Original Composition Group", "des":"Any age; Applicant's original work under 6 minutes", "price":pricelist["PPOG1"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
                },
            10:{
                "id":10,"number":"PPOG2", "name":"Ensemble Instrument Original Composition Group", "des":"Any age; Applicant's original work under 6 minutes", "price":pricelist["PPOG2"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
                },
           
        }, 
    },
    "rock":{
        "Solo Groups" : {
            1:{
            "id":1,"number":"RVS1", "name":"Solo Vocal Junior Group", "des":"Age 12 or below; Any 1 piece under 4 minutes", "price":pricelist["RVS1"],"name-cn":"约翰·汤普森最简单的钢琴课程第一冊","des-cn":"书本中的任何一首曲目"
            },
            2:{
            "id":2,"number":"RVS2", "name":"Solo Vocal Youth Group", "des":"Age 12-18; Any 1 piece under 4 minutes", "price":pricelist["RVS2"],"name-cn":"约翰·汤普森最简单的钢琴课程第二冊","des-cn":"书本中的任何一首曲目"
            },
            3:{
            "id":3,"number":"RVS3", "name":"Solo Vocal Young Artist Group", "des":"Age 18-25; Any 1 piece under 6 minutes", "price":pricelist["RVS3"],"name-cn":"Piano Lesson Made Easy第一冊","des-cn":"书本中的任何一首曲目"
            },
            4:{
            "id":4,"number":"RVS4", "name":"Solo Vocal Artist Group", "des":"Any age; Any 1 piece under 6 minutes", "price":pricelist["RVS4"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
            },
            5:{
            "id":5,"number":"RIS1", "name":"Solo Instrument Junior Group", "des":"Age 12 or below; Any 1 piece under 4 minutes", "price":pricelist["RIS1"],"name-cn":"约翰·汤普森最简单的钢琴课程第一冊","des-cn":"书本中的任何一首曲目"
            },
            6:{
            "id":6,"number":"RIS2", "name":"Solo Instrument Youth Group", "des":"Age 12-18; Any 1 piece under 4 minutes", "price":pricelist["RIS2"],"name-cn":"约翰·汤普森最简单的钢琴课程第二冊","des-cn":"书本中的任何一首曲目"
            },
            7:{
            "id":7,"number":"RIS3", "name":"Solo Instrument Young Artist Group", "des":"Age 18-25; Any 1 piece under 6 minutes", "price":pricelist["RIS3"],"name-cn":"Piano Lesson Made Easy第一冊","des-cn":"书本中的任何一首曲目"
            },
            8:{
            "id":8,"number":"RIS4", "name":"Solo Instrument Artist Group", "des":"Any age; Any 1 piece under 6 minutes", "price":pricelist["RIS4"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
            },
            9:{
                "id":9,"number":"ROS1", "name":"Solo Vocal Original Composition Group", "des":"Any age; Applicant's original work under 6 minutes", "price":pricelist["ROS1"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
                },
            10:{
                "id":10,"number":"ROS2", "name":"Solo Instrument Original Composition Group", "des":"Any age; Applicant's original work under 6 minutes", "price":pricelist["ROS2"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
                },
           
        }, 
        "Duo Groups" : {
            1:{
            "id":1,"number":"RVD1", "name":"Duo Vocal Junior Group", "des":"Age 12 or below; Any 1 piece under 4 minutes", "price":pricelist["RVD1"],"name-cn":"约翰·汤普森最简单的钢琴课程第一冊","des-cn":"书本中的任何一首曲目"
            },
            2:{
            "id":2,"number":"RVD2", "name":"Duo Vocal Youth Group", "des":"Age 12-18; Any 1 piece under 4 minutes", "price":pricelist["RVD2"],"name-cn":"约翰·汤普森最简单的钢琴课程第二冊","des-cn":"书本中的任何一首曲目"
            },
            3:{
            "id":3,"number":"RVD3", "name":"Duo Vocal Young Artist Group", "des":"Age 18-25; Any 1 piece under 6 minutes", "price":pricelist["RVD3"],"name-cn":"Piano Lesson Made Easy第一冊","des-cn":"书本中的任何一首曲目"
            },
            4:{
            "id":4,"number":"RVD4", "name":"Duo Vocal Artist Group", "des":"Any age; Any 1 piece under 6 minutes", "price":pricelist["RVD4"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
            },
            5:{
            "id":5,"number":"RID1", "name":"Duo Instrument Junior Group", "des":"Age 12 or below; Any 1 piece under 4 minutes", "price":pricelist["RID1"],"name-cn":"约翰·汤普森最简单的钢琴课程第一冊","des-cn":"书本中的任何一首曲目"
            },
            6:{
            "id":6,"number":"RID2", "name":"Duo Instrument Youth Group", "des":"Age 12-18; Any 1 piece under 4 minutes", "price":pricelist["RID2"],"name-cn":"约翰·汤普森最简单的钢琴课程第二冊","des-cn":"书本中的任何一首曲目"
            },
            7:{
            "id":7,"number":"RID3", "name":"Duo Instrument Young Artist Group", "des":"Age 18-25; Any 1 piece under 6 minutes", "price":pricelist["RID3"],"name-cn":"Piano Lesson Made Easy第一冊","des-cn":"书本中的任何一首曲目"
            },
            8:{
            "id":8,"number":"RID4", "name":"Duo Instrument Artist Group", "des":"Any age; Any 1 piece under 6 minutes", "price":pricelist["RID4"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
            },
            9:{
                "id":9,"number":"ROD1", "name":"Duo Vocal Original Composition Group", "des":"Any age; Applicant's original work under 6 minutes", "price":pricelist["ROD1"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
                },
            10:{
                "id":10,"number":"ROD2", "name":"Duo Instrument Original Composition Group", "des":"Any age; Applicant's original work under 6 minutes", "price":pricelist["ROD2"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
                },
           
        }, 
        "Ensemble/Choir Groups" : {
            1:{
            "id":1,"number":"RVG1", "name":"Ensemble Vocal Junior Group", "des":"Age 12 or below; Any 1 piece under 4 minutes", "price":pricelist["RVD1"],"name-cn":"约翰·汤普森最简单的钢琴课程第一冊","des-cn":"书本中的任何一首曲目"
            },
            2:{
            "id":2,"number":"RVG2", "name":"Ensemble Vocal Youth Group", "des":"Age 12-18; Any 1 piece under 4 minutes", "price":pricelist["RVD2"],"name-cn":"约翰·汤普森最简单的钢琴课程第二冊","des-cn":"书本中的任何一首曲目"
            },
            3:{
            "id":3,"number":"RVG3", "name":"Ensemble Vocal Young Artist Group", "des":"Age 18-25; Any 1 piece under 6 minutes", "price":pricelist["RVD3"],"name-cn":"Piano Lesson Made Easy第一冊","des-cn":"书本中的任何一首曲目"
            },
            4:{
            "id":4,"number":"RVG4", "name":"Ensemble Vocal Artist Group", "des":"Any age; Any 1 piece under 6 minutes", "price":pricelist["RVD4"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
            },
            5:{
            "id":5,"number":"RIG1", "name":"Ensemble Instrument Junior Group", "des":"Age 12 or below; Any 1 piece under 4 minutes", "price":pricelist["RID1"],"name-cn":"约翰·汤普森最简单的钢琴课程第一冊","des-cn":"书本中的任何一首曲目"
            },
            6:{
            "id":6,"number":"RIG2", "name":"Ensemble Instrument Youth Group", "des":"Age 12-18; Any 1 piece under 4 minutes", "price":pricelist["RID2"],"name-cn":"约翰·汤普森最简单的钢琴课程第二冊","des-cn":"书本中的任何一首曲目"
            },
            7:{
            "id":7,"number":"RIG3", "name":"Ensemble Instrument Young Artist Group", "des":"Age 18-25; Any 1 piece under 6 minutes", "price":pricelist["RID3"],"name-cn":"Piano Lesson Made Easy第一冊","des-cn":"书本中的任何一首曲目"
            },
            8:{
            "id":8,"number":"RIG4", "name":"Ensemble Instrument Artist Group", "des":"Any age; Any 1 piece under 6 minutes", "price":pricelist["RID4"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
            },
            9:{
                "id":9,"number":"ROG1", "name":"Choir Vocal Original Composition Group", "des":"Any age; Applicant's original work under 6 minutes", "price":pricelist["ROG1"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
                },
            10:{
                "id":10,"number":"ROG2", "name":"Ensemble Instrument Original Composition Group", "des":"Any age; Applicant's original work under 6 minutes", "price":pricelist["ROG2"],"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
                },
           
        }, 
    },

    

}

export default groupCat;