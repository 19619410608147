import '../../App.css';
import React, {Component} from 'react';
import {Row, Col, Button,Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import YoutubeBackground from 'react-youtube-background';

// import NavBarTop from "./navbar";
// import Footer from "./footer";
import groupCat from '../../cat';

class IYAOCNCategory extends Component {
  constructor(props){
    super(props);
    this.state = {  
       group0:groupCat["piano"][0],
       group1:groupCat["piano"][1],
       group2:groupCat["piano"][2],
       group3:groupCat["piano"][3],
       group4:groupCat["piano"][4],
       instrument: "piano",
    };

    this.changeGroup = this.changeGroup.bind(this)
    this.setval = this.setval.bind(this)
  }
  componentDidMount(){
    this.props.setval({comp:"iyao-cn",lang:"cn"})
  }
  setval(obj){
    this.setState(obj)
  }
  changeGroup(val){
   const _group = JSON.parse(JSON.stringify(groupCat[val]))
   //alert(JSON.stringify(_group))
   this.setState({group0:_group[0],group1:_group[1],group2:_group[2],group3:_group[3],group4:_group[4],instrument:val})
  }
  render(){
    return (
      <div className="category-div">
        <video loop autoPlay muted id="myVideo">
          <source
            src="../assets/category.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
        
        <div id="cat-content"> 
          <button className={[(this.state.instrument=="piano")?"cat-button-selected":"cat-button"].join(" ")} 
            style={{fontWeight:"bold",marginBottom:"7px"}} onClick={(e)=>{this.changeGroup("piano")}}>{"钢琴"}</button>
          <button className={[(this.state.instrument=="strings")?"cat-button-selected":"cat-button"].join(" ")} 
             style={{fontWeight:"bold",marginBottom:"7px"}} onClick={(e)=>{this.changeGroup("strings")}}>{"弦乐"}</button>
          <button className={[(this.state.instrument=="vocal")?"cat-button-selected":"cat-button"].join(" ")} 
             style={{fontWeight:"bold",marginBottom:"7px"}} onClick={(e)=>{this.changeGroup("vocal")}}>{"声乐"}</button>
          <button className={[(this.state.instrument=="woodwind")?"cat-button-selected":"cat-button"].join(" ")} 
             style={{fontWeight:"bold",marginBottom:"7px"}} onClick={(e)=>{this.changeGroup("woodwind")}}>{"木管乐"}</button>
          <button className={[(this.state.instrument=="brass")?"cat-button-selected":"cat-button"].join(" ")} 
             style={{fontWeight:"bold",marginBottom:"7px"}} onClick={(e)=>{this.changeGroup("brass")}}>{"铜管乐"}</button>
          <button className={[(this.state.instrument=="harp")?"cat-button-selected":"cat-button"].join(" ")} 
             style={{fontWeight:"bold",marginBottom:"7px"}} onClick={(e)=>{this.changeGroup("harp")}}>{"竖琴"}</button>
          <button className={[(this.state.instrument=="guitar")?"cat-button-selected":"cat-button"].join(" ")} 
             style={{fontWeight:"bold",marginBottom:"7px"}} onClick={(e)=>{this.changeGroup("guitar")}}>{"吉他"}</button>  
          <button className={[(this.state.instrument=="recorder")?"cat-button-selected":"cat-button"].join(" ")} 
             style={{fontWeight:"bold",marginBottom:"7px"}} onClick={(e)=>{this.changeGroup("recorder")}}>{"牧童笛"}</button>
          <button className={[(this.state.instrument=="percussion")?"cat-button-selected":"cat-button"].join(" ")} 
              style={{fontWeight:"bold",marginBottom:"7px"}} onClick={(e)=>{this.changeGroup("percussion")}}>{"敲击乐器"}</button>
          <button className={[(this.state.instrument=="special")?"cat-button-selected":"cat-button"].join(" ")} 
              style={{fontWeight:"bold",marginBottom:"7px"}} onClick={(e)=>{this.changeGroup("special")}}>{"特别组别"}</button>
        </div>
        <Container fluid style={{padding:"2% 5%",fontFamily:"Courgette"}} >
        
         { (Object.keys(this.state.group0).length>0) &&
         <div style={{padding:"0% 1%"}}>
         <Row>
          <Col xs={12} md={12} style={{textAlign:"left", marginLeft:"0%",fontSize:"1.4em"}}>{"初级组"}</Col>
          </Row> 
          <Row>
          <Col xs={2} md={1}>{"组号"}</Col>
          <Col xs={4} md={7}>{"组别"}</Col>
          <Col xs={4}  md={3}>{"描述"}</Col>
          <Col xs={2} md={1}>{"费用 (美元) "}</Col>
          </Row> 
          </div>}
          { (Object.keys(this.state.group0).length>0) &&
            Object.keys(this.state.group0).map((idx)=>
              <Row key={idx} style={{padding:"0% 1%"}}>
              <Col xs={2} md={1} style={{fontSize:"1em"}}>{this.state.group0[idx]["number"]}</Col>
              <Col xs={4} md={7}>{this.state.group0[idx]["name-cn"]}</Col>
              <Col xs={4} md={3}>{this.state.group0[idx]["des-cn"]}</Col>
              <Col xs={2} md={1}>{this.state.group0[idx]["price"]}</Col>
              </Row> 
            )
          }

          <br></br>
          {(Object.keys(this.state.group3).length>0) && 
          <div style={{padding:"0% 1%"}}>
            <Row>
          <Col xs={12} md={12} style={{textAlign:"left", marginLeft:"0%",fontSize:"1.4em"}}>{"Piano Pieces for Children Everybody's Favourite Series No.3"}</Col>
          </Row> 
          <Row>
          <Col xs={2} md={1}>{"组号"}</Col>
          <Col xs={4} md={7}>{"组别"}</Col>
          <Col xs={4}  md={3}>{"描述"}</Col>
          <Col xs={2} md={1}>{"费用 (美元) "}</Col>
          </Row>
          </div>} 
          { (Object.keys(this.state.group3).length>0) &&
            Object.keys(this.state.group3).map((idx)=>
              <Row key={idx} style={{padding:"0% 1%"}} >
              <Col xs={2} md={1} style={{fontSize:"1em"}}>{this.state.group3[idx]["number"]}</Col>
              <Col xs={4} md={7}>{this.state.group3[idx]["name-cn"]}</Col>
              <Col xs={4} md={3}>{this.state.group3[idx]["des-cn"]}</Col>
              <Col xs={2} md={1}>{this.state.group3[idx]["price"]}</Col>
              </Row> 
            )
          }
          <br></br>
          {(Object.keys(this.state.group2).length>0) &&
          <div style={{padding:"0% 1%"}}><Row>
          <Col xs={12} md={12} style={{textAlign:"left", marginLeft:"0%",fontSize:"1.4em"}}>{"年龄组（自选曲目）"}</Col>
          </Row> 
          <Row>
          <Col xs={2} md={1}>{"组号"}</Col>
          <Col xs={4} md={7}>{"组别"}</Col>
          <Col xs={4}  md={3}>{"描述"}</Col>
          <Col xs={2} md={1}>{"费用 (美元) "}</Col>
          </Row></div>} 
          { (Object.keys(this.state.group2).length>0) &&
            Object.keys(this.state.group2).map((idx)=>
              <Row key={idx} style={{padding:"0% 1%"}} >
              <Col xs={2} md={1} style={{fontSize:"1em"}}>{this.state.group2[idx]["number"]}</Col>
              <Col xs={4} md={7}>{this.state.group2[idx]["name-cn"]}</Col>
              <Col xs={4} md={3}>{this.state.group2[idx]["des-cn"]}</Col>
              <Col xs={2} md={1}>{this.state.group2[idx]["price"]}</Col>
              </Row> 
            )
          }
          <br></br>

          {(Object.keys(this.state.group1).length>0) &&
          <div style={{padding:"0% 1%"}}><Row>
          <Col xs={12} md={12} style={{textAlign:"left", marginLeft:"0%",fontSize:"1.4em"}}>{(this.state.instrument==="recorder")?"小学组別":"ABRSM/聖三一组別"}</Col>
          </Row> 
          <Row>
          <Col xs={2} md={1} style={{fontSize:"1.2em"}}>{"组号"}</Col>
          <Col xs={4} md={7} style={{fontSize:"1.2em"}}>{"组别"}</Col>
          <Col xs={4}  md={3} style={{fontSize:"1.2em"}}>{"描述"}</Col>
          <Col xs={2} md={1} style={{fontSize:"1.2em"}}>{"费用 (美元) "}</Col>
          </Row></div>}
          { (Object.keys(this.state.group1).length>0) &&
            Object.keys(this.state.group1).map((idx)=>
              <Row key={idx} style={{padding:"0% 1%"}} >
              <Col xs={2} md={1} style={{fontSize:"1em"}}>{this.state.group1[idx]["number"]}</Col>
              <Col xs={4} md={7}>{this.state.group1[idx]["name-cn"]}</Col>
              <Col xs={4} md={3}>{this.state.group1[idx]["des-cn"]}</Col>
              <Col xs={2} md={1}>{this.state.group1[idx]["price"]}</Col>
              </Row> 
            )
          }

<br></br>
          {(Object.keys(this.state.group4).length>0) &&
          <div style={{padding:"0% 1%"}}><Row>
          <Col xs={12} md={12} style={{textAlign:"left", marginLeft:"0%",fontSize:"1.4em"}}>{"独奏曲目组"}</Col>
          </Row> 
          <Row>
          <Col xs={2} md={1}>{"组号"}</Col>
          <Col xs={4} md={7}>{"组别"}</Col>
          <Col xs={4}  md={3}>{"描述"}</Col>
          <Col xs={2} md={1}>{"费用 (美元) "}</Col>
          </Row></div>}
          { (Object.keys(this.state.group4).length>0) &&
            Object.keys(this.state.group4).map((idx)=>
              <Row key={idx} style={{padding:"0% 1%"}}>
              <Col xs={2} md={1} style={{fontSize:"1em"}}>{this.state.group4[idx]["number"]}</Col>
              <Col xs={4} md={7}>{this.state.group4[idx]["name-cn"]}</Col>
              <Col xs={4} md={3}>{this.state.group4[idx]["des-cn"]}</Col>
              <Col xs={2} md={1}>{this.state.group4[idx]["price"]}</Col>
              </Row> 
            )
          }
 
          </Container>

      </div>
    );
  }
  }
  

export default IYAOCNCategory;
