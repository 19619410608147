import '../../App.css';
import React, {Component, Container} from 'react';
import {Row,Col,Image,Navbar, Nav, NavItem, NavDropdown, Dropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import YoutubeBackground from 'react-youtube-background';
// import Card from './card';
// import NavBarTop from "./navbar";
// import Footer from "./footer";
// import PaypalCheckoutBtn from './elements/paypalcheckout';
import AwardCheckout from './elements/awardcheckout';
// import laureate from '../../laureate';

const applyurl2 = "https://docs.google.com/forms/d/e/1FAIpQLSfvxwLdu32SnhvW3drDRTjfxWor_AhYuCU7uLPkhbTF_67mbg/viewform"
class IYAOCNAwards extends Component {
  constructor(props){
    super(props);
    this.state = {
       itemType:"Please select your item",
       productDescription:null,
       awardPayment:0,
    };

    this.setval = this.setval.bind(this)
    this.clickapply = this.clickapply.bind(this)
  }

  clickapply(){
    window.open(applyurl2, '_blank', 'noopener,noreferrer');
}
  setval(obj){
    this.setState(obj)
  }
  componentDidMount(){
    this.props.setval({comp:"iyao-cn",lang:"cn"}) 
  }      
      
  render(){
    return (
      <div><img src="/assets/award.png" alt="" width="100%" style={{borderRadius:"0px"}}/>
      <div className="home-content-div" style={{textAlign:"justify",textJustify:"inter-word", padding:"7%",fontFamily:"Courgette"}}>
        
        {/* PAYPAL HERE */}
    

         

        {/* PAYPAL END */}

        <h1 style={{marginTop:"0%",marginBottom:"1vh"}}>{"奖项"}</h1>
        <br/><h5>{"教师奖项 : "}</h5>

        <ul>
          <li>
            <h5><b>{"优秀导师奖"}</b> {" - 冠军、亚军、季军获奖教师"}</h5>
          </li>
          <li>
          <h5> <b>{"杰出教导奖"}</b> {" - 任何在任何小组中推荐四名或以上学生的老师"}</h5>
          </li>
        </ul>
        
        <br/>
        
        <h5>{"学生奖项 : "}</h5>

        <ul>
          <li>
          <h5><b>{"冠军至季军获奖者"}</b> {"- 参赛者将收到推荐信并被邀请将参赛作品录制成CD。"}</h5>
          </li>
          <li>
          <h5><b>{"金奖"}</b> {" - 分数85以上的参赛者"}</h5>
          </li>
          <li>
          <h5><b>{"银奖"}</b> {" - 分数在75分以上的参赛者"}</h5>
          </li>
          <li>
          <h5><b>{"铜奖"}</b> {"- 分数在65分以上的参赛者"}</h5>
          </li>
          <br/>
          <li>
          <h5>{"冠军将被邀请在我们的网站和其他社交媒体平台上展示他们的个人资料照片。"}</h5>
          </li>
          <li>
          <h5>{"奖品将以 PDF 格式的电子证书形式发送。"}</h5>
          </li>
        </ul>
        
        <br/>


        <div style={{width:"100%",overflowX:"hidden",overflowY:"hidden",msOverflowStyle:"none",scrollbarWidth:"none"}} >
          <table style={{width:"100%"}}>
            <tr>
              <th style={{whiteSpace: "nowrap"}}>&nbsp;&nbsp;{"名次奖项"}</th>
            </tr>
            <tr>
              <td style={{border:"1px solid black",borderTop:"1px solid white"}}>{"冠军"}</td>
              <td style={{border:"1px solid black",borderTop:"1px solid white"}}>{"亚军"}</td>
              <td style={{border:"1px solid black",borderTop:"1px solid white"}}>{"季军"}</td>
              <td style={{border:"1px solid black",borderTop:"1px solid white"}}>{"殿军"}</td>
            </tr>
          </table>


          <br></br>

          <table style={{width:"100%"}}>
            <tr>
              <th style={{whiteSpace: "nowrap"}}>&nbsp;&nbsp;{"杰出奖项"}</th>
            </tr>
            <tr style={{}}>
              <td style={{border:"1px solid black",textAlign:"center",width:"33%",borderTop:"1px solid white"}}>{"金奖"}</td>
              <td style={{border:"1px solid black",textAlign:"center",width:"33%",borderTop:"1px solid white"}}>{"银奖"}</td>
              <td style={{border:"1px solid black",textAlign:"center",width:"33%",borderTop:"1px solid white"}}>{"铜奖"}</td>
            </tr>
          </table>

          
          <br></br>

          <table style={{minWidth:"100%"}}>
            <tr>
              <th style={{whiteSpace: "nowrap"}}>&nbsp;&nbsp;{"教师奖项"}</th>
            </tr>
            <tr>
              <td style={{border:"1px solid black",borderTop:"1px solid white"}}>{"优秀导师奖"}</td>
              <td style={{border:"1px solid black",borderTop:"1px solid white"}}>{"杰出教导奖"}</td>
            </tr>
          </table>

        </div>

        <br/><br/>
        <h4 className="apply-body-font">{"满分100分，将对参赛者进行（但不限于）以下评价"}<sup>*</sup>:</h4>
        <br/>

        <div style={{width:"100%",overflowX:"hidden",overflowY:"hidden",msOverflowStyle:"none",scrollbarWidth:"none"}} > 
        <table>
          <tr>
            <th style={{whiteSpace: "nowrap"}}>&nbsp;&nbsp;Mark Rubrics</th>
          </tr>
          <tr>
            <td style={{border:"1px solid black",textAlign:"center",borderTop:"1px solid white"}}>{"技术"}</td>
            <td style={{border:"1px solid black",textAlign:"center",borderTop:"1px solid white"}}>{"乐感"}</td>
            <td style={{border:"1px solid black",textAlign:"center",borderTop:"1px solid white"}}>{"风格"}</td>
            <td style={{border:"1px solid black",textAlign:"center",borderTop:"1px solid white"}}>{"音色"}</td>
            <td style={{border:"1px solid black",textAlign:"center",borderTop:"1px solid white"}}>{"演奏风格"}</td>
          </tr>
        </table>
        </div>
        
        <br></br>

 
      <table style={{maxWidth:"100%",padding:"0% 1%"}}>
      <tr>
          <td>{"分数范围"}</td>
          <td>{"奖项类别"}</td>
      </tr>
      <tr>
          <td>&gt;&nbsp;90 </td>
          <td>{"获奖者或金奖"}</td>
      </tr>
      <tr>
          <td>85-90</td>
          <td>{"获奖者或金奖"}</td>
      </tr>
      <tr>
          <td>75-85 </td>
          <td>{"银奖"}</td>
      </tr>
      <tr>
          <td>65-75</td>
          <td>{"铜奖"} </td>
      </tr>
      <tr>
          <td>&lt;&nbsp;65</td>
          <td>{"证书"}</td>
      </tr>
    
  </table>

      {<div style={{marginTop:"6%",fontFamily:"Courgette",fontSize:"1em"}}>
        <p><sup>1</sup><i>{"如需奖杯和纸质证书，请在收到结果后7天内通过电子邮件通知我们。"}</i></p>
      </div>}
      

      {/* <h1 style={{marginTop:"7%",marginBottom:"1vh"}}>Champion List</h1>
                <br></br>

      <div style={{justifyContent:"center",display:"flex",flexDirection:"column",fontSize:"1.3rem"}}>
      <table className="award-laureate-table" >
      {
        Object.keys(laureate).map((key,item)=>{
          return(  
                <tr style={{border:"1px solid white"}}>
                  <td style={{textAlign:"center",borderRight:"1px solid white"}}>{key}</td>
                  <td style={{textAlign:"center"}}>{laureate[key]}</td>
                </tr>    
          )
        })
      }
      </table>
      </div> */}
      

      
      </div>
      </div>
    );
  }
  }
  

export default IYAOCNAwards;
