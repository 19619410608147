import '../../App.css';
import React, {Component, Container} from 'react';
import {Row,Col,Image,Navbar, Nav, NavItem, NavDropdown, Dropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import YoutubeBackground from 'react-youtube-background';
import Card from './card';
import NavBarTop from "./navbar";
import Footer from "./footer";
import PaypalCheckoutBtn from './elements/paypalcheckout';
import AwardCheckout from './elements/awardcheckout';
import laureate from '../../laureate';

//const applyurl2 = "https://docs.google.com/forms/d/e/1FAIpQLScClATIEBdAW7ZX4xUrU9tdBrT4p5PcMVmzwZRl53yjy0GlSw/viewform" //IYAO
const applyurl2 = "https://docs.google.com/forms/u/3/d/e/1FAIpQLSc7XMEPAMkiqwt5D_NHyhoOaZrhvZSeMhDN1DgRM7EU0pg79w/viewform" //IYFAAO
class IYFAOAwards extends Component {
  constructor(props){
    super(props);
    this.state = {
       itemType:"Please select your item",
       productDescription:null,
       awardPayment:0,
    };

    this.setval = this.setval.bind(this)
    this.clickapply = this.clickapply.bind(this)
  }

  clickapply(){
    window.open(applyurl2, '_blank', 'noopener,noreferrer');
}
  setval(obj){
    this.setState(obj)
  }
  componentDidMount(){
    document.title="International Young Fine Art Artist Open Competition"
    var link1 = document.getElementById('favicon')
    var link2 = document.getElementById('touchicon')
    link1.href="/assets/about-iyfao.png"
    link2.href="/assets/about-iyfao.png"
    this.props.setval({comp:"iyfao"}) 
  }      
      
  render(){
    return (
      <div style={{width:"100%",padding:"1% 0%"}}><img src="/assets/award.png" alt="" width="100%" style={{borderRadius:"0px"}}/>
      <div className="home-content-div" style={{textAlign:"justify",textJustify:"inter-word", padding:"0% 7%",fontFamily:"Courgette"}}>
        
      
        {/* Paypal starts */}
        
      <h1 style={{marginBottom:"1vh"}}>Award Ordering</h1>
        <br/>
        <h5>
          Please select the item you want and pay online below. You may then proceed to entering your shipment information with the button below.
        </h5>
        <div style={{display:"flex",flexDirection:"column",alignItems:"left",padding:"5px",border:"1px solid white",background:"rgba(255,255,255,0.9)",paddingLeft:"5%",paddingRight:"5%",color:"#1c1f1d",borderRadius:"10px",marginTop:"4%"}}>
            
            <h2  style={{marginLeft:"1%",marginTop:"2%",marginBottom:"3%"}}>Pay now with Credit Card or PayPal 
              <p style={{fontSize:"0.56em",textAlign:"justify"}}>*Service charge may be applied. Please double check your amount in the paypal pop-up window. Remember to take a screenshot after successful payment. 
                If you forgot, you may take a screenshot of your payment receipt email from PayPal.</p></h2>
            
                <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      {this.state.itemType}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                    <Dropdown.Item onClick={(e)=>{
                        this.setState({itemType:"Trophy, Certificate, 1 T-shirt and Reference Letter",productDescription:"IYFAAO Trophy, Certificate, 1 T-shirt, Reference Letter and Shipment",awardPayment:"120"})
                      }}>Trophy, Certificate, 1 T-shirt and Reference Letter</Dropdown.Item>
                    <Dropdown.Item onClick={(e)=>{
                        this.setState({itemType:"Trophy, Certificate, and Reference Letter",productDescription:"IYFAAO Trophy, Certificate, Reference Letter and Shipment",awardPayment:"100"})
                      }}>Trophy, Certificate, and Reference Letter</Dropdown.Item>
                    <Dropdown.Item onClick={(e)=>{
                        this.setState({itemType:"Trophy AND Certificate",productDescription:"IYFAAO Trophy, Certificate and Shipment",awardPayment:"85"})
                      }}>Trophy AND Certificate</Dropdown.Item>
                       <Dropdown.Item onClick={(e)=>{
                        this.setState({itemType:"Trophy AND 1 T-shirt",productDescription:"IYFAAO Trophy, 1 T-shirt and Shipment",awardPayment:"85"})
                      }}>Trophy AND 1 T-shirt</Dropdown.Item>
                      <Dropdown.Item onClick={(e)=>{
                        this.setState({itemType:"Trophy ONLY",productDescription:"IYFAAO Trophy and Shipment",awardPayment:"65"})
                      }}>Trophy ONLY</Dropdown.Item>
                     <Dropdown.Item onClick={(e)=>{
                        this.setState({itemType:"Certificate ONLY",productDescription:"IYFAAO Certificate and Shipment",awardPayment:"25"})
                      }}>Certificate ONLY</Dropdown.Item>
                      <Dropdown.Item onClick={(e)=>{
                        this.setState({itemType:"1 T-shirt ONLY",productDescription:"IYFAAO 1 T-shirt and Shipment",awardPayment:"25"})
                      }}>1 T-shirt ONLY</Dropdown.Item>
                       {/* <Dropdown.Item onClick={(e)=>{
                        this.setState({itemType:"Testing",productDescription:"Testing",awardPayment:"0.01"})
                      }}>Test</Dropdown.Item> */}
                    </Dropdown.Menu>
                  </Dropdown>

            <br></br>

                {
                  (this.state.itemType==="Trophy, Certificate, 1 T-shirt and Reference Letter") && 
                  <h3 style={{marginTop:"2%",marginLeft:"2%",marginRight:"2%"}}>
                    <i>{"Trophy, Certificate, 1-T-shirt, Reference Letter and Shipment Fee : USD "+this.state.awardPayment}</i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </h3>
                }
                {
                  (this.state.itemType==="Trophy, Certificate, and Reference Letter") && 
                  <h3 style={{marginTop:"2%",marginLeft:"2%",marginRight:"2%"}}>
                    <i>{"Trophy, Certificate, Reference Letter and Shipment Fee : USD "+this.state.awardPayment}</i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </h3>
                }
                {
                  (this.state.itemType==="Trophy AND Certificate") && 
                  <h3 style={{marginTop:"2%",marginLeft:"2%",marginRight:"2%"}}>
                    <i>{"Trophy, Certificate and Shipment Fee : USD "+this.state.awardPayment}</i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </h3>
                }
                {
                  (this.state.itemType==="Trophy AND 1 T-shirt") && 
                  <h3 style={{marginTop:"2%",marginLeft:"2%",marginRight:"2%"}}>
                    <i>{"Trophy, 1 T-shirt and Shipment Fee : USD "+this.state.awardPayment}</i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </h3>
                }
                {
                  (this.state.itemType==="Trophy ONLY") && 
                  <h3 style={{marginTop:"2%",marginLeft:"2%",marginRight:"2%"}}>
                    <i>{"Trophy and Shipment Fee : USD "+this.state.awardPayment}</i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </h3>
                }

                {
                  (this.state.itemType==="Certificate ONLY") && 
                  <h3 style={{marginTop:"2%",marginLeft:"2%",marginRight:"2%"}}>
                    <i>{"Certificate and Shipment Fee : USD "+this.state.awardPayment}</i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </h3>
                }
                {
                  (this.state.itemType==="1 T-shirt ONLY") && 
                  <h3 style={{marginTop:"2%",marginLeft:"2%",marginRight:"2%"}}>
                    <i>{"1 T-shirt and Shipment Fee : USD "+this.state.awardPayment}</i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </h3>
                }
                {
                  (this.state.itemType==="Testing") && 
                  <h3 style={{marginTop:"2%",marginLeft:"2%",marginRight:"2%"}}>
                    <i>{"Certificate and Shipment Fee : USD "+this.state.awardPayment}</i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </h3>
                }
              

            <div style={{marginTop:"3%",marginLeft:"10%",marginRight:"10%"}}>
         {/* { <PaypalCheckoutButton  product={{description:"IYFAAO",price:52}}/>} */}
         <AwardCheckout {...this.state}/>
          </div>

          
          </div>


            <div style={{marginTop:"4%"}}>
          <h4 className="apply-body-font" style={{fontFamily:"Courgette",fontWeight:"bolder",fontSize:"2rem"}}>If you have your payment receipt, you may now click the button below and fill in your shipment information.</h4>
          </div>
          
          <a onClick={(e)=>{this.clickapply()}}><button id="goldbutton-apply" style={{fontSize:"160%",width:"50%"}} >
          Apply for Award</button></a>
          
          {/* {"Paypal ends"} */}
        
        <h1 style={{marginTop:"0%",marginBottom:"1vh"}}>Awards</h1>
        <br/><h5>For teachers:</h5>

        <ul>
          <li>
            <h5><b>Outstanding Tutor Award</b> - Teachers of Champion, Second Prize and Third Prize winners</h5>
          </li>
          <li>
          <h5> <b>Excellent Mentorship Award</b> - Any teacher who recommends four students or above across any groups</h5>
          </li>
        </ul>
        
        <br/>
        
        <h5>For students:</h5>

        <ul>
          <li>
          <h5><b>Champion to 4th place winners</b> - Contestants can order a reference letter and be invited to have their profile displayed.</h5>
          </li>
          <li>
          <h5><b>Gold Awards</b> - Contestants with marks above 85</h5>
          </li>
          <li>
          <h5><b>Silver Awards</b> - Contestants with marks above 75</h5>
          </li>
          <li>
          <h5><b>Bronze Awards</b> - Contestants with marks above 65</h5>
          </li>
          <br/>
          <li>
          <h5>Winners will be invited to have their profile photo displayed on our website, and other social media platforms. </h5>
          </li>
          <li>
          <h5>Prizes will be in the form of e-certificates<sup>1</sup>.</h5>
          </li>
        </ul>
        
        <br/>


        <div style={{width:"100%",overflowX:"scroll",overflowY:"hidden",msOverflowStyle:"none",scrollbarWidth:"none"}} >
          <table style={{width:"100%"}}>
            <tr>
              <th style={{whiteSpace: "nowrap"}}>&nbsp;&nbsp;Places</th>
            </tr>
            <tr>
              <td style={{border:"1px solid black"}}>Champion</td>
              <td style={{border:"1px solid black"}}>Second Prize</td>
              <td style={{border:"1px solid black"}}>Third Prize</td>
              <td style={{border:"1px solid black"}}>Fourth Place</td>
            </tr>
          </table>


          <br></br>

          <table style={{width:"100%"}}>
            <tr>
              <th style={{whiteSpace: "nowrap"}}>&nbsp;&nbsp;Outstanding Awards</th>
            </tr>
            <tr style={{}}>
              <td style={{border:"1px solid black",textAlign:"center",width:"33%"}}>Gold Award</td>
              <td style={{border:"1px solid black",textAlign:"center",width:"33%"}}>Silver Award</td>
              <td style={{border:"1px solid black",textAlign:"center",width:"33%"}}>Bronze Award</td>
            </tr>
          </table>

          
          <br></br>

          <table style={{minWidth:"100%"}}>
            <tr>
              <th style={{whiteSpace: "nowrap"}}>&nbsp;&nbsp;Teacher Awards</th>
            </tr>
            <tr>
              <td style={{border:"1px solid black"}}>Outstanding Tutor Award</td>
              <td style={{border:"1px solid black"}}>Excellent Mentorship Award</td>
            </tr>
          </table>

        </div>

        <br/><br/>
        

 
      <table style={{maxWidth:"100%",padding:"0% 1%"}}>
      <tr>
          <td>Mark Range</td>
          <td>Class</td>
      </tr>
      <tr>
          <td>&gt;&nbsp;85 </td>
          <td>Winners or Gold Award</td>
      </tr>
      <tr>
          <td>75-85 </td>
          <td>Silver Award</td>
      </tr>
      <tr>
          <td>65-75</td>
          <td>Bronze Award </td>
      </tr>
      <tr>
          <td>&lt;&nbsp;65</td>
          <td>Certificate</td>
      </tr>
    
  </table>


        <br></br>
        <h4 className="apply-body-font">With full mark as 100, contestants will be evaluated with the following rubrics:</h4>
        <ol style={{listStyleType:"upper-roman"}}>
          <li><h4 style={{fontSize:"1.3em"}}>{"Creativity (40%)"}</h4></li>
          <li><h4 style={{fontSize:"1.3em"}}>{"Technique (20%)"}</h4></li>
          <li><h4 style={{fontSize:"1.3em"}}>{"Use of Color (20%)"}</h4></li>
          <li><h4 style={{fontSize:"1.3em"}}>{"Composition (20%)"}</h4></li>
          {/* <li><h4 style={{fontSize:"1.3em"}}>Musicality</h4></li>
          <li><h4 style={{fontSize:"1.3em"}}>Style</h4></li>
          <li><h4 style={{fontSize:"1.3em"}}>Tone Color</h4></li>
          <li><h4 style={{fontSize:"1.3em"}}>Presentation</h4></li> */}
        </ol>
        <br></br>
        
    {/* <table style={{maxWidth:"100%",padding:"0% 1%"}}>
    <tr>
        <td>Marks</td>
        <td>Class </td>
    </tr>
    <tr>
        <td>&lt;&nbsp;65 </td>
        <td>Severe impairment of submission’s integrity  </td>
    </tr>
    <tr>
        <td>65-75 </td>
        <td>Below satisfactory </td>
    </tr>
    <tr>
        <td>75-80 </td>
        <td>Satisfactory </td>
    </tr>
    <tr>
        <td>80-85 </td>
        <td>Merit </td>
    </tr>
    <tr>
        <td>85-90 </td>
        <td>Distinction and/or Prizes </td>
    </tr>
    <tr>
        <td>&gt;&nbsp;90 </td>
        <td>Distinction and/or Prizes </td>
    </tr>
   
</table> */}

      {<div style={{marginTop:"6%",fontFamily:"Courgette",fontSize:"1em"}}>
        <p><sup>1</sup><i>For trophies and paper certificates, please notify us within 7 days via email after receiving your results.</i></p>
      </div>}
      

      {/* <h1 style={{marginTop:"7%",marginBottom:"1vh"}}>Champion List</h1>
                <br></br> */}

      {/* <div style={{justifyContent:"center",display:"flex",flexDirection:"column",fontSize:"1.3rem"}}>
      <table className="award-laureate-table" >
      {
        Object.keys(laureate).map((key,item)=>{
          return(  
                <tr style={{border:"1px solid white"}}>
                  <td style={{textAlign:"center",borderRight:"1px solid white"}}>{key}</td>
                  <td style={{textAlign:"center"}}>{laureate[key]}</td>
                </tr>    
          )
        })
      }
      </table>
      </div> */}
      

      
      </div>
      </div>
    );
  }
  }
  

export default IYFAOAwards;
