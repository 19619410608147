import '../../App.css';
import React, {Component, Container} from 'react';
import {Image,Navbar, Nav, NavItem, NavDropdown} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import YoutubeBackground from 'react-youtube-background';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import IYFAONavBarTop from "./navbar";
import IYFAOFooter from "./footer";
const applyurl = "https://docs.google.com/forms/d/e/1FAIpQLScoSIrXM_CqsXcaw6mSZmB8f6E1MoXcis3QFeRaE_SO6cP_5A/viewform?usp=sf_link"

class IYFAOTerms extends Component {
  constructor(props){
    super(props);
    this.state = {  
       
    };

    this.setval = this.setval.bind(this)
  }
  componentDidMount(){
    document.title="International Young Fine Art Artist Open Competition"
    var link1 = document.getElementById('favicon')
    var link2 = document.getElementById('touchicon')
    link1.href="/assets/about-iyfao.png"
    link2.href="/assets/about-iyfao.png"
    this.props.setval({comp:"iyfao"})  
  }
  setval(obj){
    this.setState(obj)
  }

  render(){
    return (
      <div>
       <img src="/assets/term.png" width="100%" style={{borderRadius:"0px"}} />
      <div style={{display:"flex",flexDirection:"column",paddingLeft:"15%",paddingRight:"15%",paddingTop:"4%",paddingBottom:"2%",textAlign:"left",fontFamily:"Courgette"}}>
      <h4 style={{fontSize:"1.8em"}}><u><i>Regulations</i></u></h4>
      <ul style={{textAlign:"justify"}}>
        <li><p style={{fontSize:"1.4em"}}>The competition is open for international applicants. There are no restrictions on the applicant's origin. </p></li>
        <li><p style={{fontSize:"1.4em"}}>All jury members are prohibited from judging their own students’ submissions or entering into the competition as a contestant.</p></li>
      <li><p style={{fontSize:"1.4em"}}>Contestants are prohibited from contacting any jury members. Violation might result in disqualification.</p></li>
      <li><p style={{fontSize:"1.4em"}}>Results and comments by jury members are final decisions and are non-disputable.</p></li>
      <li><p style={{fontSize:"1.4em"}}>The organizer has the right to refuse and/or disqualify contestants who have violated the competition’s rules and regulations. </p></li>
      <li><p style={{fontSize:"1.4em"}}>Submissions should not contain content associated with extreme violence, obsenity and other indecency.</p></li>
      <li><p style={{fontSize:"1.4em"}}>Submissions that have been submitted in other occasions can be used in this competition.</p></li>
      <li><p style={{fontSize:"1.4em"}}>Please make sure the submission is clearly visible. For sculptures and handcrafts, guidlines on taking multiple shots will be given.</p></li>
      <li><p style={{fontSize:"1.4em"}}>The organizer has the right to amend competition rules, content of prizes and jury composition. Any amendments might not be announced.</p></li>
      <li><p style={{fontSize:"1.4em"}}>The official rules and regulations of the competition shall be published on the official website.</p></li>
      <li><p style={{fontSize:"1.4em"}}>The organizer has the final right to interpretation.</p></li>
    </ul>
      
     
    
    <br></br>

    <h4 style={{fontSize:"1.8em"}}><u><i>Terms</i></u></h4>
    <ul style={{textAlign:"justify"}}>
    <li><p style={{fontSize:"1.4em"}}>Contestants have the right to apply for more than one competition group. </p></li>
      <li><p style={{fontSize:"1.4em"}}>All application fees are non-refundable.</p></li>
    <li><p style={{fontSize:"1.4em"}}>All information of the contestant will be confidential, and will only be used for the purpose of contact, notification and promotion matters related to this competition.</p></li>
    <li><p style={{fontSize:"1.4em"}}>Any change of any part of the application or submission information will be subject to an administration fee of USD $15 or equivalent. </p></li>
    </ul>
       <br></br>

{/* 
        <h2>2022 International Young Artist Open Competition</h2>
        <h4>Deadline: 15th November 2022</h4>
        <h4>Results Date: 19th December 2022</h4>
        <br/>      

      <br/><br/>
    <h6>After successful payment, we will send a confirmation email to you.</h6>
    <p style={{fontSize:"0.4em"}}>*All application fees are non-refundable.</p> */}
      </div>
      </div>
    );
  }
  }
  

export default IYFAOTerms;
