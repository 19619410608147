import '../../App.css';
import React, {Component, Container} from 'react';
import {Image,Navbar, Nav, NavItem, NavDropdown} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import YoutubeBackground from 'react-youtube-background';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import NavBarTop from "./navbar";
import Footer from "./footer";
const applyurl = "https://docs.google.com/forms/d/e/1FAIpQLScoSIrXM_CqsXcaw6mSZmB8f6E1MoXcis3QFeRaE_SO6cP_5A/viewform?usp=sf_link"

class IESADJury extends Component {
  constructor(props){
    super(props);
    this.state = {  
       
    };

    this.setval = this.setval.bind(this)
  }
  componentDidMount(){
    document.title="International English Speech Art and Drama Competition"
    var link1 = document.getElementById('favicon')
    var link2 = document.getElementById('touchicon')
    link1.href="/assets/iesad_logo.png"
    link2.href="/assets/iesad_logo.png"
    this.props.setval({comp:"iesad"})  
  }
  setval(obj){
    this.setState(obj)
  }

  render(){
    return (
      <div>
       {/* <img src="/assets/term.png" width="100%" style={{borderRadius:"0px"}} /> */}
      <div style={{display:"flex",flexDirection:"column",paddingLeft:"15%",paddingRight:"15%",paddingTop:"3%",paddingBottom:"2%",textAlign:"left"}}>
      <h4 style={{fontSize:"1.8em",textAlign:"center",marginBottom:"100px"}}>Head of Jury</h4>

      <div style={{display:'block', marginBottom:"100px"}}>
        <div className="jury-text" style={{marginLeft:"0px",marginRight:"4%"}}>
            <p> 
            Dominic Sargent, via scholarship, received his bachelor degree at Trinity Hall, Cambridge University.
            At university he became known for his interpretations – in particular of Wagner - and theatre music.
            He continued his study with Frederik Prausnitz, and graduated with a Doctor of Musical Arts in
            conducting from Peabody Conservatory in the United States. </p>
            <p>In 1990 Dr Sargent moved to Berlin to work in opera, music theatre and cabaret. 
              He earned a reputation in the national press as a sensitive, lyrical accompanist and an exacting, inspirational conductor.
              Dr Sargent has travelled widely in Europe and Asia, teaching, composing, and performing. He is
            known as a highly effective practitioner. His untiring advocacy for the performing
            arts has brought him many rewarding and enriching experiences which he hopes
            to share with young artists all over the world. </p>
        </div>

        <div className="jury-profile" style={{position:"relative"}}>
        <div style={{position:"absolute"}}>
            {/* <img className="jury-flag" alt="" src="/assets/uk-flag.png"/> */}
            </div>
            <img src="/assets/dominic.jpg" alt=""  width="100%"/>
            <h4 style={{marginTop:"5px"}}><b>Dr. Dominic Sargent</b></h4>
            <h5><em>Performing Art Educationist</em></h5>
            </div>
        
        

    </div>


    
    {/* <div style={{display:'block', marginBottom:"100px"}}>
        <div className="jury-profile">
            <img src="/assets/alexandre.jpg" alt=""  width="100%"/>
            <h4 style={{marginTop:"5px"}}><b>Mr. Alexandre Teheux</b></h4>
            <h5><em>Pianist and Music Educationist</em></h5>
            </div>
        <div style={{position:"absolute"}}>
            <img className="jury-flag" alt="" src="/assets/belgium-flag.jpg" /></div>
        <div className="jury-text"><p>
            Born in Paris in 1959, Alexandre Teheux studied under Frederic Delvaux, and began his musical studies at Academie Gretry in Liege with Jeanne Bernard-Knapen.
            He then studied in Liege Conservatory with Chantal Bohets and Marcelle Mercenier. He later received a grant from Belgian government which 
            led him to travel and study at Chopin Academy in Warsaw.</p><p>
             With more than 20 years of teaching experience, Alexandre teaches regulary at Academie Gretry, as well as performing in concerts, notably with the violinist Benoit Paquay.
        </p></div>
    </div> */}

      </div>
      </div>
    );
  }
  }
  

export default IESADJury;
