import '../../App.css';
import React, {Component, Container} from 'react';
import {Image,Navbar, Nav, NavItem, NavDropdown} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import YoutubeBackground from 'react-youtube-background';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import NavBarTop from "./navbar";
import Footer from "./footer";
const applyurl = "https://docs.google.com/forms/d/e/1FAIpQLScoSIrXM_CqsXcaw6mSZmB8f6E1MoXcis3QFeRaE_SO6cP_5A/viewform?usp=sf_link"

class Jury extends Component {
  constructor(props){
    super(props);
    this.state = {  
       
    };

    this.setval = this.setval.bind(this)
  }
  componentDidMount(){
    document.title="Worldwide Pop and Jazz Music Competition"
    var link1 = document.getElementById('favicon')
    var link2 = document.getElementById('touchicon')
    link1.href="/assets/wpjm-logo.PNG"
    link2.href="/assets/wpjm-logo.PNG"
    this.props.setval({comp:"wpjmc"})
  }
  setval(obj){
    this.setState(obj)
  }

  render(){
    return (
      <div>
       {/* <img src="/assets/term.png" width="100%" style={{borderRadius:"0px"}} /> */}
      <div style={{display:"flex",flexDirection:"column",paddingLeft:"15%",paddingRight:"15%",paddingTop:"3%",paddingBottom:"2%",textAlign:"left"}}>
      <h4 style={{fontSize:"1.8em",textAlign:"center",marginBottom:"50px",marginTop:"30px"}}>Head of Jury</h4>


    <div style={{display:'block', marginBottom:"100px"}}>
        <div className="jury-profile">
            <img src="/assets/abraham.png" alt=""  width="100%"/>
            <h4 style={{marginTop:"5px"}}><b>Professor Abraham Tena Manrique</b></h4>
            <h5><em>President of the World Piano Teacher Association (Spain) - Composition</em></h5>
            </div>
        <div style={{position:"absolute"}}>
            <img className="jury-flag" alt="" src="/assets/spain-flag.png" /></div>
        <div className="jury-text"><p>
            As the President of World Piano Teachers Association-SPAIN-COMPOSITION, Socio numerario de Federacion de Asociaciones Ibericas de Compositores, Soci numerari de l'Associacio Catalana de Compositors, and Socio de la Sociedad General de Autores y Editores, 
            professor Abraham Tena Manrique is active in the composing field. In 2021 and 2022 he was the guest composer at two international competitions and festivals, WPTA Spain and MIMAS Music Festival Competition. Many contestants performed his compositions. 
            In both competitions a financial Scholarship was awared to the best performer of one of his compositions. In 2021 the <i>Da Vinci</i> publishing house concluded the ambitious project of publishing all the works composed by Prof. Manrique, resulting in 7 beautiful 
            volumes that include works for piano, piano 4 hands, the concerto for piano and orchestra Op. 42, the oratorio 'The seven last words of Christ on the Cross' Op.40, chamber works with clarinet and a lied. This news were also published in the extensive interview
            in the magazine Ritmo of January 2022 that finalized the creative notion initiated since 2017.
       </p>
       <p>In April 2018, Prof. Manrique was appointed the President of the WPTA-SPAIN-COMPOSITION (World Piano Teachers Association). The WPTA is an association that exists in more than 30 countries, where it annually organizes conferences, piano, compositions, and chamber
        music competitions, as well as recitals and masterclasses. Prof. Manrique was the President of the Court of the I International Composition Contest of the WPTA-SPAIN in 2019. Bewteen 2017 and 2018, Prof. Manrique offered various recitals where he performed the 18 works for piano
        that made up the CD published by <i>Da Vinci</i> with several of his works performed by himself.
       </p>
       <p>Without neglecting its concert facet, it is also worth mentioning the Closing Concert offered at the III Edition of the Villa de Xabia International Festival (Alicante), invited by Nati Ballarin, director and founder of the institution. Interested in the composer's piano works, she created
        the 'Abraham Tena Manrique' award for the VI Edition of the Villa de Xabia International Piano Competition - of which she was also a founder and director then in 2018.
       </p>
       <p>Prof. Manrique's work, in addition to his interpretive and editorial aspects, also include works such as the Cantata Las Siete Words de Cristo en la Cruz commissioned by Sergio Espejo, pianist of the National Orchestra and Choir of Spain (OCNE). It was present at one of the Satellite concerts
        orgainzed annually by the OCNE in the Madrid Auditorium, where the composer performed his Seneca Octet Op. Classic (RNE) in March 1, 2016.
       </p>
        <p> Recently, Maestro Tena Manrique has made the recording of all his solo piano compositions for the Japanese record label, <i>Da Vinci</i>. Recordings made in the <i>Barcelona Auditorium</i> counting for the long session a <i>Steinway Gran Cola D274</i> thanks to Pianoservei. 
        50 works that will appear on 3 CDs in a few months. In autumn-winter of 2023 he will offer some recitals to present many of the compositions. Prof. Manrique is currently the President of the WPTA-SPAIN-COMPOSITION. 
        The WPTA is an association around the world, where it organizes annual conferences, piano-playing competitions, composition and chamber music, as well as recitals and master-classes.</p></div>
    </div>


 

      </div>
      </div>
    );
  }
  }
  

export default Jury;
