import '../../App.css';
import React, {Component, Container} from 'react';
import {Row,Col,Image,Navbar, Nav, NavItem, NavDropdown, Dropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import YoutubeBackground from 'react-youtube-background';
import Card from './card';
import NavBarTop from "./navbar";
import Footer from "./footer";
import PaypalCheckoutBtn from './elements/paypalcheckout';
import AwardCheckout from './elements/awardcheckout';
import laureate from '../../laureate';

const applyurl2 = "https://docs.google.com/forms/d/e/1FAIpQLSfvxwLdu32SnhvW3drDRTjfxWor_AhYuCU7uLPkhbTF_67mbg/viewform"
class Awards extends Component {
  constructor(props){
    super(props);
    this.state = {
       itemType:"Please select your item",
       productDescription:null,
       awardPayment:0,
    };

    this.setval = this.setval.bind(this)
    this.clickapply = this.clickapply.bind(this)
  }

  clickapply(){
    window.open(applyurl2, '_blank', 'noopener,noreferrer');
}
  setval(obj){
    this.setState(obj)
  }
  componentDidMount(){
    document.title="Worldwide Pop and Jazz Music Competition"
    var link1 = document.getElementById('favicon')
    var link2 = document.getElementById('touchicon')
    link1.href="/assets/wpjm-logo.PNG"
    link2.href="/assets/wpjm-logo.PNG"
    this.props.setval({comp:"wpjmc"}) 
  }      
      
  render(){
    return (
      <div><img src="/assets/award.png" alt="" width="100%" style={{borderRadius:"0px"}}/>
      <div className="home-content-div" style={{textAlign:"justify",textJustify:"inter-word", padding:"7%",fontFamily:"Courgette"}}>

      {/* Paypal here */}


        <h1 style={{marginTop:"0%",marginBottom:"1vh"}}>Awards</h1>
        <br/><h5>For teachers:</h5>

        <ul>
          <li>
            <h5><b>Excellent Mentorship Award</b> - Teachers of Champion, Second Prize and Third Prize winners</h5>
          </li>
          <li>
          <h5> <b>Active Music Educator Award</b> - Any teacher who recommends four students or above across any groups</h5>
          </li>
        </ul>
        
        <br/>
        
        <h5>For centres and music institutions:</h5>

        <ul>
          <li>
            <h5><b>Active Music Institution Award</b> - Any organization which recommended 6 contestants or above across any groups</h5>
          </li>
          <li>
          <h5> <b>Elite Music Institution Award</b> - Any organization with 4 or more associated contestants receiving Champion, 2nd, 3rd or 4th Prize.</h5>
          </li>
        </ul>
        
        <br/>

        
        <h5>For students:</h5>

        <ul>
          <li>
          <h5><b>Champion to 4th place winners</b> - Contestants will receive a reference letter and be invited to have the submission recorded into CD.</h5>
          </li>
          <li>
          <h5><b>Gold Awards</b> - Contestants with marks above 85</h5>
          </li>
          <li>
          <h5><b>Silver Awards</b> - Contestants with marks above 75</h5>
          </li>
          <li>
          <h5><b>Bronze Awards</b> - Contestants with marks above 65</h5>
          </li>
          <li>
          <h5><b>Special Awards</b> - Awards given for specific repertoire such as:</h5>
              <ul>
              <li>
                <h6>Best Original Composition Award</h6>
                </li>
                <li>
                <h6>Best Pop Song Award</h6>
                </li>
                <li>
                <h6>Best Rhythm and Blues Award</h6>
                </li>
                <li>
                <h6>Best Pop Music Award</h6>
                </li>
                <li>
                <h6>Best Rock Music Award</h6>
                </li>
                <li>
                <h6>Best Country Music Award</h6>
                </li>
              </ul>
          </li>
          <br/>
          <li>
          <h5>Winners may order a special CD Set, and to be invited to displayed their profile on our social media. Some selected videos might be invited to be displayed on our YouTube Channel.</h5>
          </li>
          <li>
          <h5>Teachers of our winners will be invited to the Outstanding Music Teacher List, and be invited for an interview to be featured on our magazine. Winners will also be invited to feature in our magazine and displayed in winner posters on our social media.</h5>
          </li>
          <li>
          <h5>Prizes will be in the form of e-certificates.</h5>
          </li>
        </ul>
        
        <br/>


        <div style={{width:"100%",overflowX:"auto",overflowY:"hidden",msOverflowStyle:"none",scrollbarWidth:"none"}} >
          <table style={{width:"100%",borderColor:"black"}}>
            <tr>
              <th style={{whiteSpace: "nowrap"}}>&nbsp;&nbsp;Places</th>
            </tr>
            <tr>
              <td style={{border:"1px solid black",textAlign:"center"}}>Champion</td>
              <td style={{border:"1px solid black",textAlign:"center"}}>Second Prize</td>
              <td style={{border:"1px solid black",textAlign:"center"}}>Third Prize</td>
              <td style={{border:"1px solid black",textAlign:"center"}}>Fourth Place</td>
            </tr>
          </table>


          <br></br>

          <table style={{width:"100%",borderColor:"black"}}>
            <tr>
              <th style={{whiteSpace: "nowrap"}}>&nbsp;&nbsp;Class Awards</th>
            </tr>
            <tr style={{}}>
              <td style={{border:"1px solid black",textAlign:"center",width:"33%"}}>Gold Award</td>
              <td style={{border:"1px solid black",textAlign:"center",width:"33%"}}>Silver Award</td>
              <td style={{border:"1px solid black",textAlign:"center",width:"33%"}}>Bronze Award</td>
            </tr>
          </table>

          
          <br></br>

         

          <table style={{minWidth:"100%",borderColor:"black"}}>
            <tr>
              <th style={{whiteSpace: "nowrap"}}>&nbsp;&nbsp;Teacher Awards</th>
            </tr>
            <tr>
              <td style={{border:"1px solid black",textAlign:"center"}}>Active Muisc Educator Award</td>
              <td style={{border:"1px solid black",textAlign:"center"}}>Excellent Mentorship Award</td>
            </tr>
          </table>

        </div>

        <br/><br/>
        <h4 className="apply-body-font">With full mark as 100, contestants will be evaluated with (but not limited to) the following<sup>*</sup>:</h4>
        <br/>

        <div style={{width:"100%",overflowX:"hidden",overflowY:"hidden",msOverflowStyle:"none",scrollbarWidth:"none"}} > 
        <table style={{borderColor:"black"}} >
          <tr>
            <th style={{whiteSpace: "nowrap"}}>&nbsp;&nbsp;Mark Rubrics</th>
          </tr>
          <tr>
            <td style={{border:"1px solid black",textAlign:"center"}}>Technique</td>
            <td style={{border:"1px solid black",textAlign:"center"}}>Musicality</td>
            <td style={{border:"1px solid black",textAlign:"center"}}>Style</td>
            <td style={{border:"1px solid black",textAlign:"center"}}>Tone Color</td>
            <td style={{border:"1px solid black",textAlign:"center"}}>Presentation</td>
          </tr>
        </table>
        </div>
        
        <br></br>

 
      <table style={{maxWidth:"100%",borderColor:"black",padding:"0% 1%"}}>
      <tr>
          <td>Mark Range</td>
          <td>Class</td>
      </tr>
      <tr>
          <td>&gt;&nbsp;90 </td>
          <td>Winners or Gold Award</td>
      </tr>
      <tr>
          <td>85-90</td>
          <td>Winners or Gold Award</td>
      </tr>
      <tr>
          <td>75-85 </td>
          <td>Silver Award</td>
      </tr>
      <tr>
          <td>65-75</td>
          <td>Bronze Award </td>
      </tr>
      <tr>
          <td>&lt;&nbsp;65</td>
          <td>Certificate</td>
      </tr>
    
  </table>

      {<div style={{marginTop:"6%",fontFamily:"Courgette",fontSize:"1em"}}>
        <p><sup>1</sup><i>For trophies and paper certificates, please fill in the Award Order Form within 7 days via email after receiving your results.</i></p>
      </div>}
      

      {/* <h1 style={{marginTop:"7%",marginBottom:"1vh"}}>Champion List</h1>
                <br></br>

      <div style={{justifyContent:"center",display:"flex",flexDirection:"column",fontSize:"1.3rem"}}>
      <table className="award-laureate-table" >
      {
        Object.keys(laureate).map((key,item)=>{
          return(  
                <tr style={{border:"1px solid white"}}>
                  <td style={{textAlign:"center",borderRight:"1px solid white"}}>{key}</td>
                  <td style={{textAlign:"center"}}>{laureate[key]}</td>
                </tr>    
          )
        })
      }
      </table>
      </div> */}
      

      
      </div>
      </div>
    );
  }
  }
  

export default Awards;
